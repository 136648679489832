import React from "react";
import Image1 from "../images/deletetwo.jpg";
import Image2 from "../images/deletethree.jpg";
import Image3 from "../images/deletefour.jpg";
import Image4 from "../images/deletefive.jpg";

const DeleteAccount = () => {
  return (
    <div className="">
      <div className="py-5 flex justify-center items-center flex-col">
        <h1 className="lg:text-[2.8rem] md:text-4xl text-3xl  font-semibold text-center">
          Delete account on mobile app
        </h1>
        <div className="lg:w-[400px] md:w-[300px] w-[200px]  mt-2 h-[2px] bg-gradient-to-r from-[#b2b2b2] to-[#f0f0f0]"></div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-4 items-center p-5">
          <div className="flex">
            <div className="lg:text-2xl text-xl flex gap-2 font-semibold">
              <span className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-slate-700 text-white">
                1
              </span>
              Go to your profile
            </div>
          </div>

          <div className="max-w-[400px] w-[100%]">
            <img
              src={Image1}
              className="w-[100%] h-[100%]"
              alt="Delete Account"
            />
          </div>
        </div>

        <div className="flex  flex-col justify-between gap-4 items-center p-5">
          <div className="flex">
            <div className="lg:text-2xl text-xl font-semibold flex gap-2">
              <span className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-slate-700 text-white">
                2
              </span>{" "}
              Go to setting <br />
            </div>
          </div>
          <div className="max-w-[400px] w-[100%]">
            <img
              src={Image2}
              className="w-[100%] h-[100%]"
              alt="Delete Account"
            />
          </div>
        </div>

        <div className="flex  flex-col justify-between gap-4 items-center p-5">
          <div className="flex">
            <div className="lg:text-2xl text-xl gap-2 font-semibold flex">
              <span className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-slate-700 text-white">
                3
              </span>{" "}
              Click on Delete Account
            </div>
          </div>
          <div className="max-w-[400px] w-[100%]">
            <img
              src={Image3}
              className="w-[100%] h-[100%]"
              alt="Delete Account"
            />
          </div>
        </div>

        <div className="flex  flex-col justify-between gap-4 items-center p-5">
          <div className="flex">
            <div className="lg:text-2xl text-xl font-semibold gap-2 flex">
              <span className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-slate-700 text-white">
                4
              </span>{" "}
              Click on Delete Button
            </div>
          </div>
          <div className="max-w-[400px] w-[100%]">
            <img
              src={Image4}
              className="w-[100%] h-[100%]"
              alt="Delete Account"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
