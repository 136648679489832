import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import SkeletonAllTask from "../components/Skeleton/Skeleton";
import AddTask from "../components/Task/AddTask";
import { useHome } from "../utils/homeProvider";
import "../components/TaskCards/TaskCard.css";

const MyTaskList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [allTask, setAllTask] = useState();
  const { selectedHome } = useHome();
  const tabs = [
    { name: "My Tasks", icon: <IoEyeOutline /> },
    { name: "Add Task", icon: <HiOutlinePlus /> },
  ];

  const url = window.location.href;
  const pathSegments = url.split("/");
  const staffTasksName = pathSegments.find(
    (segment) => segment === "staff-tasks"
  );

  const GetAllTask = async (id, token) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/staff-tasks?homeId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setAllTask(data?.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const storedSelectedHome = localStorage.getItem("selectedHome");
    const selectedHome = JSON.parse(storedSelectedHome);

    if (userDataObj) {
      GetAllTask(selectedHome?.id, userDataObj?.token);
    }
  }, [selectedHome]);

  return (
    <div className="flex" style={{ height: "calc(100vh - 90px)" }}>
      {/* Sidebar */}

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-y-auto">
        {loading ? (
          <SkeletonAllTask />
        ) : allTask?.staff.length > 0 ? (
          <div className="task-container">
            {allTask.staff.map((task, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (staffTasksName === "staff-tasks") {
                      navigate(`/staff-tasks/${task?.id}`);
                    } else {
                      navigate(`/my-tasks/${task?.id}`);
                    }
                  }}
                  className={`h-[80px] relative max-w-[500px] w-full rounded-xl ${
                    task?.hasOverdueTasks === false && task?.hasPendingTasks === false && task.totalTasks !== 0
                      ? "bg-[#E8F8FF]"
                      : task?.hasOverdueTasks === true && task?.hasPendingTasks === true?
                      "bg-[#FFECEC]":task?.hasOverdueTasks === false && task?.hasPendingTasks === false && task.totalTasks === 0?"bg-[#E9E9E9]":"bg-[#FFF9F0]"
                  } cursor-pointer hover:bg-gray-50 hover:shadow-md transition-all duration-200`}
                >
                  <div
                    className={`absolute top-1/2 left-0 w-[4px] h-[60%] ${
                      task?.hasOverdueTasks === false && task?.hasPendingTasks === false && task.totalTasks !== 0
                      ? "bg-[#205368]"
                      : task?.hasOverdueTasks === true && task?.hasPendingTasks === true?
                      "bg-[#E64646]":task?.hasOverdueTasks === false && task?.hasPendingTasks === false && task.totalTasks === 0?"bg-[#616161]":"bg-[#DE9C36]"
                    }  rounded-full transform -translate-y-1/2`}
                  ></div>
                  <div className="flex justify-between items-center flex-row h-full p-2 gap-3">
                    <div className="flex flex-row items-center justify-center gap-2">
                      <div className="flex items-center justify-center rounded-full w-9 h-9 bg-gray-400">
                        {task?.imageUrl ? (
                          <img
                            className="bg-transparent rounded-full w-full h-full"
                            src={task?.imageUrl}
                            alt="alt"
                          />
                        ) : (
                          <span className="text-white text-sm font-medium text-[13px]">
                            {task?.firstName?.[0]}
                            {task?.lastName?.[0]}
                          </span>
                        )}
                      </div>
                      <div>
                        <div className="flex gap-2 items-center">
                          <h1 className="text-red-600 font-bold text-[18px]">
                            ({task?.isCheckedIn ? "P" : "A"})
                          </h1>
                          <h1 className="text-base font-bold tracking-wide text-[18px]">
                            {task?.firstName} {task.lastName}
                          </h1>
                        </div>
                        <div>
                          <h1 className="text-base font-[400] text-slate-600 text-left text-[18px]">
                            Task: {task?.completedTasks}/{task?.totalTasks}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center text-gray-500 text-lg justify-center">
            No Staff Added.
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTaskList;
