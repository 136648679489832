import React from "react";

const Privacy = () => {
  return (
    <div className="px-2.5 bg-white text-start">
      <div className="max-w-screen-xl mx-auto">
        <header className="flex flex-col lg:flex-row justify-between items-center py-3">
          <a href="/">
            <span className="font-bold text-black capitalize">
              Home<span className="text-orange-600">360&deg;</span>
            </span>
          </a>
        </header>
      </div>

      <div className="max-w-screen-xl mx-auto px-5">
        <div className="mt-4 md:mt-6 text-center">
          <h1 className="text-xl lg:text-3xl font-bold lg:tracking-tight">
            Privacy Policy
          </h1>
        </div>
      </div>
      <div className="flex flex-col gap-5 mx-auto max-w-4xl my-8 text-black">
        <div>
          <h1 className="text-[16px]">Last Updated on [.]</h1>
        </div>

        <div>
          <h1 className="text-[16px] tracking-wide leading-normal">
            This Mobile Application (<b>“Platform”</b>) is operated by Aptos
            Innovations Private Limited having its registered office at SDI
            House FP-27/1, Off Bhayli Canal Road Near Aarna 48, Vadodara,
            Gujarat – 391410 (hereinafter referred to as <b>“Company”</b>,{" "}
            <b>“Home360”</b>, <b>“We”</b>, <b>“Us”</b>, <b>“Our”</b>). We offer
            tools for holistic management of household (<b>“Services”</b>).
          </h1>
        </div>

        <div>
          <h1 className="text-[16px] tracking-wide leading-normal">
            This Policy provides for the practices for the collection,
            possession, storage, handling and dealing of personal identifiable
            information/ data and sensitive personal data (hereinafter
            collectively referred to as <b>“Personal Information”</b>) of the
            User. This Policy is an electronic record in terms of Information
            Technology Act, 2000 and rules thereunder.
          </h1>
        </div>

        <div>
          <h1 className="text-[16px] tracking-wide leading-normal">
            The Company urges the User to read and understand this Policy and
            the Terms of Service available at [.] (<b>“ToS”</b>) carefully for
            details on the way. By accessing Our Platform or availing Our
            Services, You consent to the collection, process, use, disclosure,
            store and transfer of Your Personal Information in accordance with
            this Policy and for the purposes stipulated herein.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            1. Who does this Policy apply to?
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            This Policy will apply to (a) all persons accessing Our Platform;
            and (b) all persons using any of Our Services offered on Our
            Platform. (hereinafter referred to as <b>“User</b>, <b>“You”</b>,
            <b>“Your”</b>). [<b>LK Comment: Client to confirm</b>]
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            2. What data do we collect?
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            In the course of providing the Services to the Users through the
            Platform, We may collect the following personal information (
            <b>“Personal Information”</b>): [
            <b>
              LK Comment: Client to confirm the personal information that we
              collect. We have not added email id as per your comment in the
              Terms of Service.
            </b>
            ]
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First name
            and last name
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone
            number
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">(c)</h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Home360 to
            insert other details that will be taken in relation to making
            payments on the platform].
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            3. How do we collect Your data?
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            We collect data and process Personal Information when You:
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Register on
            Our Platform.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Voluntarily
            complete a customer survey or provide feedback on any of our message
            boards.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Use Our
            Platform, by collecting session tokens, API tokens, cookies etc. [
            <b>LK Comment Client to confirm</b>]
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From data
            that You share through the chat/ messaging function on Platform. You
            are responsible for any content You share on chat and messaging
            features. This content will also be accessible to other Users who
            can then share that information further. We recommend that You
            consider carefully what You share and with whom You share such
            information.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <b>Add any other ways your company collects data</b>]
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            Our Company also receives Your data indirectly from the following
            sources:
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <b>
              Add any ways through which any third party shares data with
              Company
            </b>
            ]
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            4. How We use Your Personal Information
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            We use the Personal Information for the following purposes:
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Providing Our Service:</b> The processing of Personal Information
            is important to facilitate the User to use Services vide the
            Platform.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Marketing and advertising:</b> We use Personal Information to
            provide Users with special offers, and promotions, and for other
            marketing, advertising, and promotional purposes, including general
            updates about Us and Our Services and new offerings of the Company
            or any third-party partner services. These communications will be
            sent in accordance with Your communications preferences, if you
            specifically opt-in for receiving the same. At any time, You may opt
            out of such communications as per Paragraph 9.2(e) below. [
            <b>
              LK Comment: Please note that specific retractable consent should
              be used for marketing and advertising
            </b>
            ]
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Lead Generation:</b> For generating business enquiries and trade
            leads.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Improving the Services:</b> To analyze User’s choices, browsing
            pattern including the frequency of visits and duration for which a
            User is logged on, to diagnose problems with Our servers, for
            improving Our systems and Platform and Services. We may combine the
            information that we receive from third parties with the information
            you give to us and information we collect about you for the purposes
            set out above. We analyse the log files of Our Platform that may
            contain Internet Protocol (IP) addresses, browser type and language,
            Internet service provider (ISP), referring, app crashes, page viewed
            and exit websites and applications, operating system, date/time
            stamp, and clickstream data. This helps us to administer the
            website, to learn about user behavior on the site, to improve our
            product and services, and to gather demographic information about
            our user base as a whole.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>New services:</b> We shall approach the User for the User’s
            consent for any other specific purpose not listed in this Policy.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Comply with legal obligations:</b> To meet Our legal obligations
            under the applicable laws.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Legitimate Interests:</b> To fulfil Our legitimate interests, or
            those of a third party in conducting and managing Our business and
            Our relationship with You as described in this Policy.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Security:</b> In order to protect You and other Users from
            fraudulent activities and transactions and to protect You from
            unauthorized access.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Market research, data solutions and report creation:</b> We may
            use your Personal Information for market research and data solution
            purposes. This is done via functionality reporting on the data
            collected from You.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            <b>Anonymization:</b> We may anonymize and/or de-identify
            information collected from you through the Services or via other
            means, including via the use of third-party web analytic tools. As a
            result, our use and disclosure of aggregated and/or de-identified
            information is not restricted by this Policy, and it may be used and
            disclosed to others without limitation. Personal Information which
            We may convert into the anonymized format is Our property. We may
            use it, in Our sole discretion and without any compensation to the
            User, for any legitimate purpose including without limitation the
            transfer of the anonymized format to the third parties.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            5. What are Cookies and how do we collect and use them?
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            We use Cookies to personalize the User’s experience on the Platform
            and the advertisements that may be displayed.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            Cookies are small pieces of information that are stored by the
            User’s application on the User’s device’s hard drive. Cookies allow
            Us to serve the User better and more efficiently (<b>“Cookies”</b>).
          </h1>

          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            Type of Cookies We Use [LK Comment: Client to confirm.]
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies can be
            "Persistent" or "Session" Cookies. Persistent Cookies remain on
            Platform when You go offline, while Session Cookies are deleted as
            soon as You close Your Platform.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We use both Session and
            Persistent Cookies for the purposes set out below:
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Necessary / Essential
            Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type:
            Session Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered
            by: Us
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose:
            These Cookies are essential to provide You with Services available
            through the Platform, ancillary services including authentication of
            the payments and to enable You to use some of its features. They
            help to authenticate Users and prevent fraudulent use of User
            accounts. Without these Cookies, the Services that You have asked
            for cannot be provided, and We only use these Cookies to provide You
            with those Services.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies Policy /
            Notice Acceptance Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type:
            Persistent Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered
            by: Us
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose:
            These Cookies identify if Users have accepted the use of Cookies on
            the Platform.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Functionality Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type:
            Persistent Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered
            by: Us
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose:
            These Cookies allow us to remember choices You make when You use the
            Platform, such as remembering your login details or language
            preference. The purpose of these Cookies is to provide You with a
            more personal experience and to avoid You having to re-enter your
            preferences every time You use the Platform.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Additional Services Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type:
            Persistent Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered
            by: Third-Parties
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose:
            These Cookies are used for availing other services on the Platform
            that are outsourced to the third-party service providers like
            payment gateways, customer care team etc.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            (v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tracking and Performance Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type:
            Persistent Cookies
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered
            by: Third-Parties
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Purpose: These Cookies are used to track information about traffic
            to the Platform and how Users use the Platform. The information
            gathered via these Cookies may directly or indirectly identify You
            as an individual visitor. This is because the information collected
            is typically linked to a pseudonymous identifier associated with the
            device You use to access the Platform. We may also use these Cookies
            to test new advertisements, pages, features, or new functionality of
            the Platform to see how our Users react to them.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal ps-8">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            If You prefer to avoid the use of Cookies on the Platform, first You
            must disable the use of Cookies in your Platform and then delete the
            Cookies saved in your mobile application associated with this
            Platform. You may use this option for preventing the use of Cookies
            at any time.
          </h1>

          <h1 className="text-[16px]  tracking-wide leading-normal">
            The User can control how and whether Cookies will be accepted by the
            User’s device, however, blocking Our Cookies may disable certain
            features on the Platform, and may hinder an otherwise seamless
            experience to purchase or use certain Services available on the
            Platform.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            6. Where is Your Personal Information stored?
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            The Personal Information that We collect in respect of the User is
            hosted within [India]. [<b>LK Comment: Client to confirmF</b>]
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            7. Why and when we transfer/ disclose Your Personal Information?
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            We may transfer the User’s Personal Information within India and
            outside India (provided that such country offers the same or greater
            level of protection for Personal Information than India), in the
            following manner: [
            <b>
              LK Comment: Client to confirm if data will be transferred outside
              India.
            </b>
            ]
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            To affiliated companies and entities, within India or outside India,
            that have been contracted to offer services on behalf of the
            Company. These service providers or vendors are only permitted to
            use the disclosed Personal Information in accordance with the
            instructions provided by the Company via binding documents
            enforceable by the law.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            If required by law, legal process, litigation, and/or requests from
            governmental or regulatory authorities, or for the purpose of
            national security, law enforcement, or other issues of public
            importance, including cyber incidents, prosecution and punishment of
            offences, to disclose the User’s Personal Information.
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            In connection with a sale, divesture, or transfer of Company
            (including any shares) or any combination of its products, Services,
            assets, affiliates, and/or businesses. The User’s Personal
            Information may be among the items sold or otherwise acquired in
            these types of transactions. We may also sell, assign or otherwise
            transfer such Personal Information in the course of corporate
            divestitures, mergers, acquisitions, bankruptcies, dissolutions,
            reorganizations, liquidations, similar transactions or proceedings.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            8. How do We protect Your Personal Information?
          </h1>
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            Security Safeguards and Procedure
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We value
            the security of the User’s Personal Information and hence We have
            implemented technical and organizational security measures that are
            designed to help protect the User’s Personal Information from
            unauthorized access, disclosure, use and modification. From
            time-to-time, We review Our security procedures to consider
            appropriate new technologies and methods.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please be
            aware, however, that no data security measures can guarantee 100%
            security.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If We learn
            of a security breach, We may (a) attempt to notify the User
            electronically so that the User can take appropriate protective
            steps; or (b) post a notice on the Platform, or (c) take suo-motto
            steps to rectify the breach without any notice to the User. If the
            User have any questions about the security of the User’s Personal
            Information, please contact the Grievance Officer.
          </h1>

          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            How long We keep Personal Information
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We shall
            retain the User’s Personal Information only for as long as is
            necessary, and after such reasonable time, it is destroyed
            appropriately and securely or anonymized in accordance with the law.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We shall
            retain the User’s Personal Information (other than in anonymized
            form) for: (i) as long as User’s account is active and We have a
            continued relationship with the User; (ii) as long as needed in
            order to comply Our legal, statutory and contractual obligations;
            (iii) as long as needed to provide Services to the User and shall be
            subject to periodic update or deletion of unnecessary data.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            9. What are Your Rights and obligations in relation to Your Personal
            Information?
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            Save and except the rights provided herein in this Policy, all
            rights to the Personal Information shall belong to the User. The
            Users shall ensure the following:
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To provide
            true and accurate details of Personal Information.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To keep
            their information up to date.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To
            regularly review this Policy.
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To keep
            their account credentials confidential and notify the Company of any
            security breaches.
          </h1>

          <h1 className="text-[16px]  tracking-wide leading-normal">
            You have the following rights pertaining to the Personal Information
            shared by You:
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to
            know what Personal Information We hold and to have the information
            communicated to You;
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to
            access the copies of Your Personal Information. We may charge You a
            small fee for this service;
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to
            request that the Personal Information held by Us be corrected,
            rectified or deleted;
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to
            object to the processing of the Personal Information or to request
            restriction thereof;
          </h1>

          <h1 className="text-[16px] tracking-wide leading-normal">
            (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to
            opt out of targeted advertisements. This can be done by managing
            your preferences [here] or you can follow the 'unsubscribe'
            instructions in commercial messages. Please note that it may take up
            to thirty (30) business days for Us to process opt-out requests.
            Please note that, even after You opt out from receiving marketing or
            promotional messages from Us, You may continue to receive
            transactional and service-related messages, certain administrative
            notices, service notices, or legal notices from the Company;
          </h1>
          <h1 className="text-[16px] tracking-wide leading-normal">
            (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to
            withdraw Your consent to submit or process any or all Personal
            Information or decline to provide any permissions on its Platform as
            covered above at any time.
          </h1>

          <h1 className="text-[16px]  tracking-wide leading-normal">
            Any such request to update, delete or correct the Personal
            Information, or to withdraw consent regarding sharing and use of
            Personal Information should be shared with the Grievance Officer as
            per Paragraph 14 below. Please note that we may take time to process
            such requests, and your request shall take effect no later than 5
            (Five) business days from the receipt of such request, after which
            we will not use your personal data for any processing unless
            required by us to comply with our legal obligations.
          </h1>

          <h1 className="text-[16px]  tracking-wide leading-normal">
            In case You choose to object processing of the Personal Information,
            withdraw consent in regard to the Personal Information or delete the
            Personal Information shared, then Your access to the Platform and
            Services may be limited, or We might not be able to provide the
            Services to You.
          </h1>

          <h1 className="text-[16px]  tracking-wide leading-normal">
            We will evaluate any request for deletion of Personal Information on
            a case-to-case basis and take the requisite action as per applicable
            law. In this regard, please note that information sought to be
            deleted may remain with Us in archival records for the purpose of
            compliance of statutory enactments, or for any other lawful purpose.
            Therefore, You are requested to carefully evaluate what types of
            information they would like to provide to Us.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            10. Links to third party website/apps
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            We may through Our Platform, provide links to third-party platforms.
            We do not make any representations or warranties with respect to
            such third-party platforms. Further, We are not responsible for the
            practices employed by such platforms, applications, or services
            linked to or from the Services or Platform, including the
            information or content contained there. Such third-party websites
            and services are subject to that third party’s own rules, policies,
            and practices, and not this Policy.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            11. Should children access this Platform?
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            We do not knowingly collect or process personal information of
            children below age of 18 years without obtaining verifiable parental
            consent to process the personal information.
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            Accounts for children between the age of 18 years old, must be
            created by their parent or legal guardian. If You are a parent or
            guardian and You are aware that Your child has provided Us with
            Personal Information, please contact Us. If We become aware that We
            have collected Personal Information from anyone under the age of 18
            without verification of parental consent, We will take steps to
            remove that information from Our servers.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            12. How do we update this Policy?
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            This Policy is current as of the last updated date set forth above.
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            We may periodically change this Policy to keep pace with new
            technologies, industry practices, and regulatory requirements, among
            other reasons. While, We expect most such changes to be minor, We
            request the Users periodically to review the changes.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            13. Governing Law
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            This Policy shall be governed by and construed in accordance with
            the laws of India and the Parties agree that they are bound by the
            laws of India for the purposes of this Policy. The Parties agree
            that the Company shall not be required to follow laws of any other
            jurisdiction, whether or not such User belongs to a different
            jurisdiction other than India.
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            Parties shall try to resolve any dispute arising out of or in
            relation to this Policy by mutual discussions, failing which the
            same shall be submitted to arbitration under the provisions of the
            Arbitration and Conciliation Act, 1996. The place of arbitration
            shall be Vadodara, Gujarat, India and the language of arbitration,
            English. Subject to the foregoing, the courts at Vadodara, Gujarat,
            India shall have exclusive jurisdiction.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            14. How to contact Us?
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            Any complaints with regards to use, processing or disclosure of
            Personal Information provided by the User or breach of the terms of
            this Policy may be taken up with the Grievance Officer. The details
            of the Grievance Officer are as follows:
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            Grievance Officer: Rajveer Parikh
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            <a
              className="text-blue-600 underline "
              href="mailto:mail@aptosinnovations.com"
            >
              Email: mail@aptosinnovations.com
            </a>
            <br />
            Address: SDI House FP-27/1, Off Bhayli Canal Road Near Aarna 48,
            Vadodara, Gujarat –391410
          </h1>

          <h1 className="text-[16px]  tracking-wide leading-normal">
            The Grievance Officer shall acknowledge and dispose of the complaint
            within such time period as prescribed under the applicable laws.
          </h1>
        </div>

        <div className="flex flex-col gap-4">
          <h1 className="text-[16px] font-bold tracking-wide leading-normal">
            15. How to contact the appropriate authority
          </h1>
          <h1 className="text-[16px]  tracking-wide leading-normal">
            Should you wish to report a complaint or if you feel that the
            Company has not addressed your concern in a satisfactory manner, you
            may contact the [<b>•</b>].
          </h1>
        </div>
      </div>
      <footer className="py-10">
        <p className="text-center text-sm text-white">
          Copyright © 2024 Home360&deg;.
        </p>
      </footer>
    </div>
  );
};

export default Privacy;
