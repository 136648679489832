import { createContext, useContext, useState } from "react";

const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
  const [selectedHome, setSelectedHome] = useState(() => {
    const storedHome = localStorage.getItem("selectedHome");
    return storedHome ? JSON.parse(storedHome) : null;
  });

  const updateSelectedHome = (home) => {
    setSelectedHome(home);
    localStorage.setItem("selectedHome", JSON.stringify(home));
  };

  return (
    <HomeContext.Provider value={{ selectedHome, updateSelectedHome }}>
      {children}
    </HomeContext.Provider>
  );
};

export const useHome = () => useContext(HomeContext);
