import React from "react";
const skeletons = Array(3).fill(0);

const SkeletonAllTask = () => {
  return (
    <div className="task-container">
      {skeletons.map((_, index) => (
        <div
          key={index}
          className="h-[80px] relative max-w-[500px] w-[100%] rounded-xl bg-gray-100 animate-pulse"
        >
          <div className="absolute top-1/2 left-0 w-[4px] h-[60%] bg-gray-300 rounded-full transform -translate-y-1/2"></div>
          <div className="flex justify-between items-center flex-row h-full p-2 gap-3">
            <div className="flex flex-row items-center justify-center gap-2">
              {/* Avatar Skeleton */}
              <div className="flex items-center justify-center rounded-full w-9 h-9 bg-gray-300"></div>
              <div className="flex flex-col gap-1">
                {/* Name Skeleton */}
                <div className="h-4 w-28 bg-gray-300 rounded"></div>
                {/* Task Skeleton */}
                <div className="h-4 w-40 bg-gray-300 rounded"></div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {/* Button Skeletons */}
              <div className="w-7 h-7 bg-gray-300 rounded-full"></div>
              <div className="w-7 h-7 bg-gray-300 rounded-full"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonAllTask;
