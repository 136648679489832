import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Task/Home";
import HomePage from "./components/HomePage/HomePage";
import AddTask from "./components/Task/AddTask";
import Navbar from "./components/Navbar/Navbar";
import MyTaskList from "./pages/MyTaskList";
import MyTaskById from "./pages/MyTaskById";
import Privacy from "./pages/Privacy";
import TaskDetails from "./pages/TaskDetails";

import Sidebar from "./components/Sidebar/Sidebar";
import { useState } from "react";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Footer from "./components/Footer/Footer";
import DeleteAccount from "./pages/DeleteAccount";
import { Procedures } from "./components/Procedures/Procedures";
import ProceduresDetails from "./pages/ProceduresDetails";

function App() {
  const location = useLocation();
  const hideSidebar =
    location.pathname === "/" || location.pathname === "/home";
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  //  const [selectedHome, setSelectedHome] = useState(null);
  const storedHome = localStorage.getItem("selectedHome");
  let userRole = null;
  if (storedHome) {
    const homeData = JSON.parse(storedHome); // Parse the stored JSON string

    if (homeData.members && homeData.members.length > 0) {
      const houseManager = homeData?.members[0]?.staffDetail[0]?.category;

      // Assuming there's only one member per user session
      userRole = homeData.members[0].role; // Extract the role
    }
  }

  return (
    <div className="App">
      <div className="">
        <Navbar />
      </div>
      <div className="flex flex-row">
        {!hideSidebar && (
          <div
            style={{ height: "calc(100vh - 75px)" }}
            className={`${
              isSidebarOpen ? "w-[125px]" : "w-24" // Sidebar width based on the state
            } transition-all duration-300 ease-in-out overflow-hidden`}
          >
            <Sidebar
              navigate={navigate}
              location={location}
              setIsSidebarOpen={setIsSidebarOpen}
              isSidebarOpen={isSidebarOpen}
            />
          </div>
        )}
        <div className={`flex-1 ${hideSidebar ? "" : ""}`}>
          <Routes>
            {/* <Route path="/login" element={<LoginForm />} /> */}
            {/* <Route path="/" element={<HomePage />} /> */}
            <Route
              path="/home"
              element={
                // <ProtectedRoute>
                <Home />
                // </ProtectedRoute>
              }
            />
            {userRole !== "Staff" && ( // Conditionally render the AddTask route
              <>
                <Route
                  path="/add-tasks"
                  element={
                    <ProtectedRoute>
                      <AddTask />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/my-tasks"
                  element={
                    <ProtectedRoute>
                      <MyTaskList />
                    </ProtectedRoute>
                  }
                />
              </>
            )}

            <Route
              path="/my-tasks/:id"
              element={
                <ProtectedRoute>
                  <MyTaskById />
                </ProtectedRoute>
              }
            />

            <Route
              path="/my-tasks/:staffId/:taskId"
              element={
                <ProtectedRoute>
                  <TaskDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path="/staff-tasks"
              element={
                <ProtectedRoute>
                  <MyTaskList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/procedures"
              element={
                <ProtectedRoute>
                  <Procedures />
                </ProtectedRoute>
              }
              />

            <Route
              path="/staff-tasks/:id"
              element={
                <ProtectedRoute>
                  <MyTaskById />
                </ProtectedRoute>
              }
            />

            <Route
              path="/staff-tasks/:staffId/:taskId"
              element={
                <ProtectedRoute>
                  <TaskDetails />
                </ProtectedRoute>
              }
            />

            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </div>
      </div>

      <Routes>
        {/* <Route path="/login" element={<LoginForm />} /> */}
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/home" element={<Home />} />
      <Route path="/add-tasks" element={<AddTask />} /> */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
      </Routes>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
