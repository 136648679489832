import React, { useState } from 'react';
import '../../styles/Login.css';
import { useNavigate } from 'react-router-dom';
import { notification } from "antd";

const LoginForm = ({ closeModal,onLogin }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
  const [api, contextHolder] = notification.useNotification();

    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const openNotification = (placement, message, status, width) => {
      api.info({
        message: `${message}`,
        placement,
        duration: 5,
        style: {
          width: width,
        },
        className: `${
          status === 200
            ? "custom-notification-success"
            : status === 401
            ? "custom-notification-error"
            : ""
        }`,
      });
    };


    const isValidPhoneNumber = () => {
        const phoneRegex = /^[6-9]\d{9}$/; // Matches 10-digit numbers starting with 6, 7, 8, or 9
        return phoneRegex.test(phoneNumber);
    };
    const handlePhoneSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/register-with-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber, countryCode: '+91' }),
            });
            if (response.ok) {
                
                setIsOtpSent(true);
            }
        } catch (error) {
            console.error('Error:', error);
         
        } finally {
            setLoading(false);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/verify-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber, otp }),
            });
            if (response.ok) {
                const res = await response.json();
                const localStorageData = {
                    token: res.data.token,
                    firstName: res.data.user.firstName,
                    lastName: res.data.user.lastName
                };
            
                // Convert the object into a string and store it in localStorage
                localStorage.setItem('userData', JSON.stringify(localStorageData));
                onLogin(localStorageData)
                closeModal();
                navigate('/home');
            } else {
                const errorData = await response.json();
                openNotification(
                  "top",
                  `${errorData.data.error}`,
                  401,
                  "500"
                );
             
            }
        } catch (error) {
          openNotification(
            "top",
            "Something Went Wrong, Try Again Later!",
            401,
            "500"
          );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center">
          {contextHolder}
        <div
          className="absolute top-[160px] bg-white w-[400px] h-[400px] rounded-lg shadow-lg p-9"
        >
          {/* Close Button */}
          <button
              onClick={closeModal}
              className="absolute  w-[30px] h-[30px] rounded-full bg-[#294859] text-white -top-3 -right-3"
            >
              ✕
            </button>

            {/* Phone Input */}
            <div className="mt-4">
              <div className="flex items-center border rounded-md p-2 bg-white">
                <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center mr-2">
                  <svg className="w-4 h-4 text-gray-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" fill="currentColor"/>
                    <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" fill="currentColor"/>
                  </svg>
                </div>
                <input
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  maxLength="12"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="w-full outline-none text-gray-700"
                />
              </div>
            </div>

            {/* Get OTP Button */}
             {!isOtpSent ? (
              <button 
                onClick={handlePhoneSubmit}
                disabled={!isValidPhoneNumber() || loading}
                className="w-full bg-[#DB6312] text-white py-2 rounded-[14px] mt-4 disabled:opacity-50"
              >
                {loading ? 'Sending...' : 'GET OTP'}
              </button>
            ) : (
              <form onSubmit={handleOtpSubmit}>
                {/* OTP Input */}
                <div className="mt-4">
                  <div className="flex items-center border rounded-md p-2">
                    <span className="mr-2">🔒</span>
                    <input
                      type="password"
                      placeholder="• • • • • •"
                      className="w-full outline-none"
                      maxLength="6"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                </div>
                
                <button 
                  type="submit"
                  disabled={otp.length !== 6 || loading}
                  className="w-full bg-[#DB6312] text-white py-2 rounded-[14px] mt-4 disabled:opacity-50"
                >
                  {loading ? 'Verifying...' : 'Verify OTP'}
                </button>
              </form>
            )}
        </div>
      </div>
    );
};

export default LoginForm;