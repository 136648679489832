// src/pages/TaskDetailsPage.jsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { MdArrowBackIos } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IoMdCheckmark } from "react-icons/io";
import moment from "moment";
import { Tooltip } from "@mui/joy";
import { useLocation } from 'react-router-dom';

const TaskDetails = () => {
  const { staffId, taskId } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [staffRole, setStaffRole] = useState(null);

const { state } = useLocation();
const { startTime, endTime } = state || {};
  const fetchTaskDetails = async () => {
    setLoading(true);
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const selectedHome = JSON.parse(localStorage.getItem("selectedHome"));
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/${taskId}?homeId=${selectedHome.id}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      setTask(data?.data?.task);
      setSchedule(data?.data?.task?.taskSchedule[0]);
      setStaffRole(selectedHome?.members[0]?.role);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching task details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskDetails();
  }, []);

  let newScheduleText = "";

  if (task) {
    const frequency = schedule?.frequency; // e.g., Monthly, Yearly, etc.
    const onDay = schedule.onDays?.[0];
    const nthDay = schedule.onNthDaysOfWeek?.[0];
    const weekDay = schedule.onWeekDays?.[0];
    const weekDays = schedule.onWeekDays || [];
    const interval = schedule.interval;
    const month = schedule.onMonths?.[0];

    const formattedDate = moment(schedule?.startDate).format("DD MMM, YYYY");
    const formattedEndDate = schedule?.endDate
      ? moment(schedule?.endDate).format("DD MMM, YYYY")
      : "";

    if (task.taskFrequency === "YEARLY") {
      if (onDay) {
        newScheduleText = `Occurs  every ${
          interval === 1 ? "" : interval
        } year on day ${onDay} of ${
          month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()
        } starting from ${formattedDate} ${
          formattedEndDate ? `to ${formattedEndDate}` : ``
        }`;
      } else {
        newScheduleText = `Occurs  every ${
          interval === 1 ? "" : interval
        } year on the ${nthDay} ${weekDays} of ${
          month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()
        }   starting from ${formattedDate} ${
          formattedEndDate ? `to ${formattedEndDate}` : ``
        }`;
      }
    }
    if (task.taskFrequency === "MONTHLY") {
      newScheduleText = `Occurs on ${
        onDay
          ? `day ${onDay} of every ${interval === 1 ? "" : interval} month`
          : `the ${nthDay} ${weekDays} of every ${
              interval === 1 ? "" : interval
            } month`
      } starting from ${formattedDate} ${
        formattedEndDate ? ` to ${formattedEndDate}` : ""
      }`;
      newScheduleText = `Occurs on ${
        onDay
          ? `day ${onDay} of every ${interval === 1 ? "" : interval} month`
          : `the ${nthDay} ${weekDays} of every ${
              interval === 1 ? "" : interval
            } month`
      } starting from ${formattedDate} ${
        formattedEndDate ? ` to ${formattedEndDate}` : ""
      }`;
    }
    if (task.taskFrequency === "DAILY") {
      newScheduleText = `Occurs every ${
        interval === 1 ? "" : interval
      } day starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }
    if (task.taskFrequency === "WEEKLY") {
      const weekDaysText = weekDays
        .map((day) => {
          return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
        })
        .join(", ");
      newScheduleText = `Occurs every ${
        interval === 1 ? "" : interval
      } week on ${weekDaysText} starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
      newScheduleText = `Occurs every ${
        interval === 1 ? "" : interval
      } week on ${weekDaysText} starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }
    if (task.taskFrequency === "EVERY_WORK_DAY") {
      newScheduleText = `Occurs every work day ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }
    if (task.taskFrequency === "DOES_NOT_REPEAT") {
      newScheduleText = `on ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }

    if (task.taskFrequency === "CUSTOM") {
      const formattedDate = moment(task?.taskSchedule[0].startDate).format(
        "DD MMM, YYYY"
      );
      const formattedEndDate = task?.taskSchedule[task?.taskSchedule.length - 1]
        .startDate
        ? moment(
            task?.taskSchedule[task?.taskSchedule.length - 1].startDate
          ).format("DD MMM, YYYY")
        : "";
      newScheduleText = `Occurs on ${formattedDate} ${
        formattedEndDate ? `, ${formattedEndDate}` : ``
      }`;
    }
  }
function formateDate(date){
 return moment(date).format(
    "DD MMM, YYYY"
  );
}
  function formatTime(startTime) {
    const hours = Math.floor(startTime / 100); // Extract hours
    const minutes = startTime % 100; // Extract minutes
    const period = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, "0"); // Add leading zero if needed

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  const url = window.location.href;
  const pathSegments = url.split("/");
  const staffTasksName = pathSegments.find(
    (segment) => segment === "staff-tasks"
  );

  console.log(staffTasksName);

  return (
    <div className="px-4">
      <div className="py-4">
        <h1
          className="text-[16px] items-center text-black hover:text-slate-600 flex flex-row font-semibold cursor-pointer"
          onClick={() => {
            if (staffTasksName === "staff-tasks") {
              navigate(`/staff-tasks/${staffId}`);
            } else {
              navigate(`/my-tasks/${staffId}`);
            }
          }}
        >
          <MdArrowBackIos size={20} />
          Back to Tasks
        </h1>
      </div>
      <div className="flex flex-col gap-4 pb-10">
        <h1 className="text-4xl text-slate-800 text-center font-bold">
          Task Details
        </h1>
        {loading ? (
          <div className="text-start flex flex-col md:flex-row justify-center max-w-[1000px] w-[100%] mx-auto py-4">
            <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4 px-1">
              {/* Title */}
              <div className="h-[30px] w-[70%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Task Status */}
              <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Assigned To */}
              <div className="h-[20px] w-[80%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Task Schedule */}
              <div className="h-[20px] w-[60%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Last Completion Date */}
              <div className="h-[20px] w-[40%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Start Time */}
              <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* End Time */}
              <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Next Due Date */}
              <div className="h-[20px] w-[40%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Additional Details */}
              <div className="h-[20px] w-[60%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Task Description */}
              <div className="h-[40px] w-[90%] bg-gray-300 rounded-md animate-pulse"></div>
            </div>
            <div className="w-[1px] bg-gray-200"></div>
            <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4 px-1">
              {/* Procedure Steps */}
              <div className="h-[100px] w-[90%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Assign to in case of absence */}
              <div className="h-[20px] w-[70%] bg-gray-300 rounded-md animate-pulse"></div>
              {/* Require completion Picture */}
              <div className="flex flex-row items-center gap-2">
                <div className="w-[18px] h-[18px] bg-gray-300 rounded-sm animate-pulse"></div>
                <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {staffRole && staffRole === "Staff" ? (
              <div className="text-start flex flex-col md:flex-row justify-center  max-w-[1000px] w-[100%] mx-auto  py-4">
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4  px-1">
                  <h1 className="text-[20px] font-bold">{task?.title}</h1>
                  {task?.taskType === "ONE_TIME" && task?.taskFrequency && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                    Task Schedule:
                    <span className="text-[18px] font-[400] text-gray-800">
                      {" "}
                      {newScheduleText}
                    </span>
                    {/* <p>{newScheduleText}</p> */}
                  </h1>
                  )}
                 
                  {task?.taskType === "RECURRING" && task?.lastCompletion && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                      Last completion date:{" "}
                      {task?.lastCompletion
                        ? formateDate(task?.lastCompletion)
                        : "--"}
                    </h1>
                  )}
                  {task?.nextOccurance && (
                    <h1 className="text-[18px] font-[500] text-gray-700">
                      Next due date:{" "}
                      {task?.nextOccurance
                        ? formateDate(task?.nextOccurance)
                        : "--"}
                    </h1>
                  )}
                </div>
                {/* <div className="w-[1px]  bg-gray-200"></div> */}
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4  px-1">
                  <h1 className="text-[18px] font-[600] text-black">
                    Additional Details:
                  </h1>
                  {task?.description && (
                    <h1 className="text-[18px] font-[600] text-black ">
                      Task Description:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {task?.description}
                      </span>
                    </h1>
                  )}
                  <h1 className="text-[18px] font-normal text-gray-600">
                    Assigned by:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {task?.createdByUser?.firstName}{" "}
                      {task?.createdByUser?.lastName}
                    </span>
                  </h1>

                  {task?.procedureSteps && task?.procedureSteps?.length > 0 && (
                    <div className="mt-2">
                      <ul className="list-disc list-inside">
                        {task?.procedureSteps?.map((step, index) => (
                          <li key={index} className="flex items-center gap-2">
                            <div className="w-[18px] flex flex-col justify-center items-center h-[18px]  rounded-sm border-slate-900 border-[2px]"></div>
                            {step}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {task?.taskPhotos?.filter(
                    (photo) => photo && photo.trim() !== ""
                  ).length > 0 && (
                    <div className="flex flex-col gap-2">
                      <h1 className="text-[18px] font-[600] text-black">
                        Task Photos
                      </h1>

                      <div className="flex flex-wrap gap-4">
                        {task?.taskPhotos
                          .filter((photo) => photo && photo.trim() !== "") // Filter out empty strings
                          .map((i, index) => (
                            <Tooltip
                              key={index}
                              title={
                                <div className="relative w-[300px] h-[300px] bg-white p-2">
                                  <img
                                    className="w-full h-full object-contain"
                                    src={i}
                                    alt={`tooltip-img-${index}`}
                                  />
                                </div>
                              }
                              placement="top"
                              arrow
                              enterDelay={100} // Optional: Adds a delay before showing the tooltip
                              leaveDelay={100} // Optional: Adds a delay before hiding the tooltip
                            >
                              <div className="w-[75px] h-[75px] cursor-pointer rounded-md overflow-hidden">
                                <img
                                  className="w-[100%] h-[100%] object-cover"
                                  src={i}
                                  alt={`task-img-${index}`}
                                />
                              </div>
                            </Tooltip>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-start flex flex-col md:flex-row justify-center  max-w-[1000px] w-[100%] mx-auto  py-4">
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4  px-1">
                  <h1 className="text-[20px] font-bold">{task?.title}</h1>
                  <h1 className="text-[18px] font-normal text-gray-600 ">
                    Task Status:{" "}
                    <span
                      className={`${
                        task?.taskStatus === "completed"
                          ? "text-[#205368]"
                          : task?.taskStatus === "pending"
                          ? "text-[#DE9C36]"
                          : "text-[#DE9C36]"
                      }`}
                    >
                      {task && task?.taskStatus === "scheduled"
                        ? "Pending"
                        : task?.taskStatus.charAt(0).toUpperCase() +
                          task?.taskStatus.slice(1).toLowerCase()}
                    </span>
                  </h1>
                  <h1 className="text-[18px] font-normal text-gray-600">
                    Assigned to:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {task?.assignedToUser?.firstName}{" "}
                      {task?.assignedToUser?.lastName}
                    </span>
                  </h1>
                  {task?.taskType === "RECURRING" && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                      Task Frequency:
                      <span className="text-[18px] font-[400] text-gray-800">
                        {" "}
                        {task?.taskFrequency.charAt(0).toUpperCase() +
                          task?.taskFrequency.slice(1).toLowerCase()}
                      </span>
                    </h1>
                  )}

                  <h1 className="text-[18px] font-normal text-gray-600">
                    Task Schedule:
                    <span className="text-[18px] font-[400] text-gray-800">
                      {" "}
                      {newScheduleText}
                    </span>
                    {/* <p>{newScheduleText}</p> */}
                  </h1>
                  {task?.taskType === "RECURRING" && task?.lastCompletion && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                      Last completion date:{" "}
                      {task?.lastCompletion
                        ? formateDate(task?.lastCompletion)
                        : "--"}
                    </h1>
                  )}

                  <h1 className="text-[18px] font-normal text-gray-600">
                    Start time:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {startTime}
                    </span>
                  </h1>

                  <h1 className="text-[18px] font-normal text-gray-600">
                    End time:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {endTime ? endTime : "--"}
                    </span>
                  </h1>

                  {task?.nextOccurance && (
                    <h1 className="text-[18px] font-[500] text-gray-700">
                      Next due date:{" "}
                      {task?.nextOccurance
                        ? formateDate(task?.nextOccurance)
                        : "--"}
                    </h1>
                  )}
                </div>
                <div className="w-[1px]  bg-gray-200"></div>
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4  px-1">
                  <h1 className="text-[18px] font-[600] text-black">
                    Additional Details:
                  </h1>
                  {staffRole && staffRole === "Admin" && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                      Assigned by:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {task?.createdByUser?.firstName}{" "}
                        {task?.createdByUser?.lastName}
                      </span>
                    </h1>
                  )}

                  {task?.description && (
                    <h1 className="text-[18px] font-[600] text-black ">
                      Task Description:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {task?.description}
                      </span>
                    </h1>
                  )}

                  {task?.procedureSteps && task?.procedureSteps?.length > 0 && (
                    <div className="mt-2">
                      <ul className="list-disc list-inside">
                        {task?.procedureSteps?.map((step, index) => (
                          <li key={index} className="flex items-center gap-2">
                            <div className="w-[18px] flex flex-col justify-center items-center h-[18px]  rounded-sm border-slate-900 border-[2px]"></div>
                            {step}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                   {task?.assignedToSubUser && (
                      <h1 className="text-[18px] font-normal text-gray-500">
                      Assign to in case of absensce:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {task?.assignedToSubUser?.firstName}{" "}
                        {task?.assignedToSubUser?.lastName}
                      </span>
                    </h1>
                    )}

                  <div className="flex flex-col gap-3">
                    {task?.taskType === "RECURRING" && (
                      <h1 className="text-[18px] font-normal text-gray-500 flex flex-row items-center gap-2">
                        <div className="w-[18px] flex flex-col justify-center items-center h-[18px] bg-slate-800 rounded-sm border-slate-900 border-[2px]">
                          {task?.isCompletionRequired ? (
                            <IoMdCheckmark size={18} className="text-white" />
                          ) : (
                            <RxCross2 size={18} className="text-white" />
                          )}
                        </div>{" "}
                        Require completion if Skipped
                      </h1>
                    )}
                    <h1 className="text-[18px] font-normal text-gray-500 flex flex-row items-center gap-2">
                      <div className="w-[18px] flex flex-col justify-center items-center h-[18px] bg-slate-800 rounded-sm border-slate-900 border-[2px]">
                        {task?.isCompletionPictureRequired ? (
                          <IoMdCheckmark size={18} className="text-white" />
                        ) : (
                          <RxCross2 size={18} className="text-white" />
                        )}
                      </div>{" "}
                      Require completion Picture
                    </h1>
                  </div>

                  {task?.taskPhotos?.filter(
                    (photo) => photo && photo.trim() !== ""
                  ).length > 0 && (
                    <div className="flex flex-col gap-2">
                      <h1 className="text-[18px] font-[600] text-black">
                        Task Photos
                      </h1>

                      <div className="flex flex-wrap gap-4">
                        {task?.taskPhotos
                          .filter((photo) => photo && photo.trim() !== "") // Filter out empty strings
                          .map((i, index) => (
                            <Tooltip
                              key={index}
                              // title={`Photo ${index + 1}`}
                              title={
                                <div className="relative w-[300px] h-[300px] bg-white p-2">
                                  <img
                                    className="w-full h-full object-contain"
                                    src={i}
                                    alt={`tooltip-img-${index}`}
                                  />
                                </div>
                              }
                              placement="top"
                              arrow
                              enterDelay={100} // Optional: Adds a delay before showing the tooltip
                              leaveDelay={100} // Optional: Adds a delay before hiding the tooltip
                            >
                              <div className="w-[75px] h-[75px] cursor-pointer rounded-md overflow-hidden">
                                <img
                                  className="w-[100%] h-[100%] object-cover"
                                  src={i}
                                  alt={`task-img-${index}`}
                                />
                              </div>
                            </Tooltip>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TaskDetails;
