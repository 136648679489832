import { ConfigProvider, Input, Radio, Select } from "antd";
import dayjs from "dayjs";
import {
  daysOfWeek,
  frequency,
  MonthNames,
  NthDaysOfWeek,
  WeekDays,
} from "../../utils/taskSchedule";
import { ReactComponent as DownArrow } from "../../images/down-arrow.svg";
import {
  GenericDatePicker,
  GenericTimePicker,
} from "../../utils/genericComponent";
export const Daily = ({ taskData, handleInputChange, index }) => {
  return (
    <>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <GenericDatePicker
            label="Start Date"
            required
            value={taskData.taskSchedule[0].startDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "startDate")
            }
          />
        </div>
        <div className="w-[50%]">
          <GenericDatePicker
          endDate = {false}
            label="End Date"
            value={taskData.taskSchedule[0].endDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "endDate")
            }
          />
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <div className="input-group">
            <label className="text-left text-[12px] text-[#262626]">
              Repeat Every
            </label>
            <Input
              // size="large"
              className="h-[30px]"
              variant="filled"
              type="number"
              value={taskData.taskSchedule[0].interval}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only up to 2 digits
                if (/^\d{0,2}$/.test(value)) {
                  handleInputChange(
                    parseInt(value, 10),
                    "taskSchedule",
                    index,
                    "interval"
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="w-[50%] flex">
          <div className="input-group flex justify-end">
            <Select
              suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
              // size="large"
              className="h-[30px]"
              variant="filled"
              name="frequency"
              value={taskData.taskSchedule[0].frequency}
              onChange={(value) =>
                handleInputChange(value, "taskSchedule", index, "frequency")
              }
            >
              {frequency.map((item, index) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );
};

export const EveryWorkDay = ({ taskData, handleInputChange, index }) => {
  // const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  return (
    <>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <GenericDatePicker
            className="h-[30px] text-sm placeholder:text-xs px-2"
            label="Start Date"
            required
            value={taskData.taskSchedule[0].startDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "startDate")
            }
          />
        </div>
        <div className="w-[50%]">
          <GenericDatePicker
          endDate = {false}
            className="h-[30px] text-sm placeholder:text-xs px-2 see"
            label="End Date"
            value={taskData.taskSchedule[0].endDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "endDate")
            }
          />
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <div className="input-group">
            <label className="text-left text-[12px] text-[#262626]">
              Repeat Every
            </label>
            <Input
              className="h-[30px]"
              // size="large"
              variant="filled"
              type="number"
              value={taskData.taskSchedule[0].interval}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only up to 2 digits
                if (/^\d{0,2}$/.test(value)) {
                  handleInputChange(
                    parseInt(value, 10),
                    "taskSchedule",
                    index,
                    "interval"
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="w-[50%] flex justify-end">
          <div className="input-group flex justify-end">
            <Select
              suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
              // size="large"
              className="h-[30px]"
              variant="filled"
              name="frequency"
              value={taskData.taskSchedule[0].frequency}
              onChange={(value) =>
                handleInputChange(value, "taskSchedule", index, "frequency")
              }
            >
              {frequency.map((item, index) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        {daysOfWeek.map((day, index) => (
          <button
            disabled
            key={index}
            className={`w-[25px] h-[25px] text-[12px]  rounded-full flex items-center justify-center text-[#262626] ${
              taskData.taskSchedule[0].onWeekDays?.includes(day.full)
                ? "bg-[#204353] text-[white]"
                : "bg-transparent text-[#262626]"
            }`}
            onClick={() => {
              const currentDays = taskData.taskSchedule[0].onWeekDays || [];
              const newDays = currentDays.includes(day.full)
                ? currentDays.filter((d) => d !== day.full)
                : [...currentDays, day.full];
              handleInputChange(newDays, "taskSchedule", index, "onWeekDays");
            }}
          >
            {day.short}
          </button>
        ))}
      </div>
    </>
  );
};

export const Weekly = ({ taskData, handleInputChange, index }) => {
  return (
    <>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <GenericDatePicker
            label="Start Date"
            required
            value={taskData.taskSchedule[0].startDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "startDate")
            }
          />
        </div>
        <div className="w-[50%]">
          <GenericDatePicker
          endDate = {false}
            label="End Date"
            value={taskData.taskSchedule[0].endDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "endDate")
            }
          />
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <div className="input-group">
            <label className="text-left text-[12px] text-[#262626]">
              Repeat Every
            </label>
            <Input
              // size="large"
              className="h-[30px]"
              variant="filled"
              type="number"
              value={taskData.taskSchedule[0].interval}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only up to 2 digits
                if (/^\d{0,2}$/.test(value)) {
                  handleInputChange(
                    parseInt(value, 10),
                    "taskSchedule",
                    index,
                    "interval"
                  );
                }
              }}
            />
          </div>
        </div>

        <div className="w-[50%] flex">
          <div className="input-group flex justify-end">
            <Select
              suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
              // size="large"
              className="h-[30px]"
              variant="filled"
              name="frequency"
              value={taskData.taskSchedule[0].frequency}
              onChange={(value) =>
                handleInputChange(value, "taskSchedule", index, "frequency")
              }
            >
              {frequency.map((item, index) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        {daysOfWeek.map((day, i) => (
          <button
            key={i}
            className={`w-[25px] h-[25px] text-[12px] rounded-full flex items-center justify-center text-[#262626] ${
              taskData.taskSchedule[0].onWeekDays?.includes(day.full)
                ? "bg-[#204353] text-[white]"
                : "bg-transparent text-[#262626]"
            }`}
            onClick={() => {
              const currentDays = taskData.taskSchedule[0].onWeekDays || [];
              const newDays = currentDays.includes(day.full)
                ? currentDays.filter((d) => d !== day.full)
                : [...currentDays, day.full];
              handleInputChange(newDays, "taskSchedule", index, "onWeekDays");
            }}
          >
            {day.short}
          </button>
        ))}
      </div>
    </>
  );
};

export const Monthly = ({
  taskData,
  handleInputChange,
  handleRadioChange,
  isRadioSelected,
  index,
}) => {
  return (
    <>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <GenericDatePicker
            label="Start Date"
            required
            value={taskData.taskSchedule[0].startDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "startDate")
            }
          />
        </div>
        <div className="w-[50%]">
          <GenericDatePicker
          endDate = {false}
            label="End Date"
            value={taskData.taskSchedule[0].endDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "endDate")
            }
          />
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <div className="input-group">
            <label className="text-left text-[12px] text-[#262626]">
              Repeat Every
            </label>
            <Input
              // size="large"
              className="h-[30px]"
              variant="filled"
              type="number"
              value={taskData.taskSchedule[0].interval}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only up to 2 digits
                if (/^\d{0,2}$/.test(value)) {
                  handleInputChange(
                    parseInt(value, 10),
                    "taskSchedule",
                    index,
                    "interval"
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="w-[50%] flex">
          <div className="input-group flex justify-end">
            <Select
              suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
              // size="large"
              className="h-[30px]"
              variant="filled"
              name="frequency"
              value={taskData.taskSchedule[0].frequency}
              onChange={(value) =>
                handleInputChange(value, "taskSchedule", index, "frequency")
              }
            >
              {frequency.map((item, index) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="flex">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#205368",
              colorBorder: "#205368",
              dotSize: 8,
              lineWidth: 2, // Set primary color to red
            },
            components: {
              Radio: {
                dotSize: 8,
                colorPrimary: "#205368",
                colorBorder: "#205368",
                lineWidth: 2, // Set Radio-specific token
              },
            },
          }}
        >
          <Radio.Group
            className="flex flex-col gap-1  w-full"
            value={isRadioSelected} // Bind to a state variable or field in taskData
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleRadioChange(selectedValue, index);
            }}
          >
             <div className="flex flex-wrap gap-2 ">
              <div className="flex items-center">
                <Radio  defaultChecked value="On Day">
                  On Day
                </Radio>
              </div>
              <div className="gap-4 flex flex-1">
                <Input
                  name="onDays"
                  className=" w-[100%]  h-[30px]"
                  variant="filled"
                  value={taskData.taskSchedule[0]?.onDays?.[0] || ""}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (/^\d{0,2}$/.test(value)) {
                      handleInputChange(
                        value === "" ? [] : [parseInt(value, 10)], // Convert to array
                        "taskSchedule",
                        index,
                        "onDays"
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap gap-2 ">
              <div className="flex  items-center">
                <Radio value="On The">On The</Radio>
              </div>
              <div className="gap-4 flex flex-1">
                <Select
                  suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
                  // size="large"
                  className="h-[30px] w-[100%]"
                  style={{ textAlign: "start" }}
                  variant="filled"
                  name="onNthDaysOfWeek"
                  value={taskData.taskSchedule[0].onNthDaysOfWeek}
                  onChange={(value) =>
                    handleInputChange(
                      value === "" ? [] : [parseInt(value, 10)],
                      "taskSchedule",
                      index,
                      "onNthDaysOfWeek"
                    )
                  }
                >
                  {NthDaysOfWeek.map((item, index) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
                  // size="large"
                  className="h-[30px] w-[100%]"
                  style={{ textAlign: "start" }}
                  variant="filled"
                  name="onWeekDays"
                  value={taskData.taskSchedule[0].onWeekDays}
                  onChange={(value) =>
                    handleInputChange(
                      [value],
                      "taskSchedule",
                      index,
                      "onWeekDays"
                    )
                  }
                >
                  {WeekDays.map((item, index) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Radio.Group>
        </ConfigProvider>
      </div>
    </>
  );
};

export const Yearly = ({
  taskData,
  handleInputChange,
  handleRadioChange,
  isRadioSelected,
  index,
}) => {
  return (
    <>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <GenericDatePicker
            label="Start Date"
            required
            value={taskData.taskSchedule[0].startDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "startDate")
            }
          />
        </div>
        <div className="w-[50%]">
          <GenericDatePicker
          endDate = {false}
            label="End Date"
            value={taskData.taskSchedule[0].endDate}
            onChange={(date, dateString) =>
              handleInputChange(dateString, "taskSchedule", index, "endDate")
            }
          />
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="w-[50%]">
          <div className="input-group">
            <label className="text-left text-[12px] text-[#262626]">
              Repeat Every
            </label>
            <Input
              // size="large"
              className="w-[100%] h-[30px]"
              variant="filled"
              value={taskData.taskSchedule[0].interval}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only up to 2 digits
                if (/^\d{0,2}$/.test(value)) {
                  handleInputChange(
                    value === "" ? "" : parseInt(value, 10),
                    "taskSchedule",
                    index,
                    "interval"
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="w-[50%] flex">
          <div className="input-group flex justify-end">
            <Select
              suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
              // size="large"
              className="h-[30px]"
              variant="filled"
              name="frequency"
              value={taskData.taskSchedule[0].frequency}
              onChange={(value) =>
                handleInputChange(value, "taskSchedule", index, "frequency")
              }
            >
              {frequency.map((item, index) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="flex">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#205368",
              colorBorder: "#205368",
              dotSize: 8,
              lineWidth: 2, // Set primary color to red
            },
            components: {
              Radio: {
                dotSize: 8,
                colorPrimary: "#205368",
                colorBorder: "#205368",
                lineWidth: 2, // Set Radio-specific token
              },
            },
          }}
        >
          <Radio.Group
            className="flex flex-col gap-1 flex-1"
            value={isRadioSelected} // Bind to a state variable or field in taskData
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleRadioChange(selectedValue, index);
            }}
          >
             <div className="flex gap-2 ">
             <div className="flex items-center">
                <Radio
                  className="h-[30px] flex items-center justify-center text-[#262626]"
                  defaultChecked
                  value="On Day"
                >
                  On Day
                </Radio>
              </div>
              <div className="gap-4 flex flex-1 ">
                {isRadioSelected === "On Day" && (
                  <Select
                    suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
                    className="text-left h-[30px]"
                    style={{ width: 150 }}
                    variant="filled"
                    name="onMonths"
                    value={taskData.taskSchedule[0]?.onMonths?.[0] || "JANUARY"}
                    onChange={
                      (value) =>
                        handleInputChange(
                          [value],
                          "taskSchedule",
                          index,
                          "onMonths"
                        ) 
                    }
                  >
                    {MonthNames.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                <Input
                  name="onDays"
                  style={{ width: 150 }}
                  className="h-[30px]"
                  variant="filled"
                  value={taskData.taskSchedule[0]?.onDays?.[0] || ""}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (/^\d{0,2}$/.test(value)) {
                      handleInputChange(
                        value === "" ? [] : [parseInt(value, 10)], // Convert to array
                        "taskSchedule",
                        index,
                        "onDays"
                      );
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap flex-row gap-y-2 gap-x-2">
              <div className="flex items-start">
                <Radio
                  className="h-[30px] flex items-center justify-center text-[#262626]"
                  value="On The"
                >
                  On The
                </Radio>
              </div>
              <div className="flex flex-col gap-1 flex-1">
                <div className="gap-4 flex">
                  <Select
                    suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
                    // size="large"
                    className="text-left h-[30px]"
                    style={{ width: "100%" }}
                    variant="filled"
                    name="onNthDaysOfWeek"
                    value={taskData.taskSchedule[0].onNthDaysOfWeek?.[0] || 1}
                    onChange={(value) =>
                      handleInputChange(
                        value === "" ? [] : [parseInt(value, 10)],
                        "taskSchedule",
                        index,
                        "onNthDaysOfWeek"
                      )
                    }
                  >
                    {NthDaysOfWeek.map((item, index) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <Select
                    suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
                    // size="large"
                    style={{ width: "100%" }}
                    variant="filled"
                    className="text-left h-[30px]"
                    name="onWeekDays"
                    value={taskData.taskSchedule[0].onWeekDays?.[0] || "MONDAY"}
                    onChange={(value) =>
                      handleInputChange(
                        [value],
                        "taskSchedule",
                        index,
                        "onWeekDays"
                      )
                    }
                  >
                    {WeekDays.map((item, index) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="gap-4 flex items-baseline">
                  {isRadioSelected === "On The" && (
                    <Select
                      suffixIcon={
                        <DownArrow style={{ height: 14, width: 14 }} />
                      }
                      // size="large"
                      className="text-left h-[30px]"
                      style={{ width: "100%" }}
                      variant="filled"
                      name="onMonths"
                      value={
                        taskData.taskSchedule[0]?.onMonths?.[0] || "JANUARY"
                      }
                      onChange={
                        (value) =>
                          handleInputChange(
                            [value],
                            "taskSchedule",
                            index,
                            "onMonths"
                          ) // Ensure array structure
                      }
                    >
                      {MonthNames.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
            </div>
          </Radio.Group>
        </ConfigProvider>
      </div>
    </>
  );
};

export const Custom = ({
  taskData,
  handleInputChange,
  addNewSchedule,
  index,
}) => {
  

  const formatTimeValue = (time) => {
    return time
      ? dayjs()
          .hour(Math.floor(time / 100))
          .minute(time % 100)
      : null;
  };
  return (
    <>
      {taskData.taskSchedule.map((schedule, scheduleIndex) => (
        <div key={scheduleIndex}>
          <div className="">
            <GenericDatePicker
              label="Start Date"
              required
              value={schedule.startDate}
              onChange={(date, dateString) =>
                handleInputChange(
                  dateString,
                  "taskSchedule",
                  index,
                  "startDate",
                  scheduleIndex
                )
              }
            />
          </div>
          <div className="flex gap-[10px]">
            <div className="w-[50%]">
              <GenericTimePicker
                label="Start Time"
                value={formatTimeValue(schedule.startTime)}
                onChange={(time, timeString) =>
                  handleInputChange(
                    timeString,
                    "taskSchedule",
                    index,
                    "startTime",
                    scheduleIndex
                  )
                }
              />
            </div>
            <div className="w-[50%]">
              <GenericTimePicker
                label="End Time"
                value={formatTimeValue(schedule.endTime)}
                onChange={(time, timeString) =>
                  handleInputChange(
                    timeString,
                    "taskSchedule",
                    index,
                    "endTime",
                    scheduleIndex
                  )
                }
              />
            </div>
          </div>
        </div>
      ))}
      <button
        className="text-[#294859] underline text-[14px] pt-1"
        onClick={addNewSchedule}
      >
        +Add New
      </button>
    </>
  );
};
