import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../styles/Home.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";

const Home = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [homes, setHomes] = useState([]);

  const [selectedHomeId, setSelectedHomeId] = useState(null);
  const [isHomeModalOpen, setIsHomeModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  let userRole = null;
  let userId = null;

  const [api, contextHolder] = notification.useNotification();
  const message = "Payment options coming soon";

  const openNotification = (placement, message, status, width) => {
    api.info({
      message: `${message}`,
      placement,
      duration: 5,
      style: {
        width: width,
      },
      className: `${
        status === 200
          ? "custom-notification-success"
          : status === 401
          ? "custom-notification-error"
          : ""
      }`,
    });
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    if (!userDataObj) {
      navigate("/");
      return;
    }
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/homes`,
          {
            headers: {
              Authorization: `Bearer ${userDataObj.token}`,
            },
          }
        );
        setLoading(false);
        setHomes(response.data.data);
        // setUserData(response.data);

        const storedSelectedHome = localStorage.getItem("selectedHome");
        if (storedSelectedHome) {
          const selectedHome = JSON.parse(storedSelectedHome);
          setSelectedHomeId(selectedHome.id);
          setIsHomeModalOpen(false);
        } else {
          // Open modal only if no home is selected
          if (response.data.data.length > 1) {
            setIsHomeModalOpen(true);
          } else if (response.data.data.length === 1) {
            // Automatically select the single available home
            const singleHome = response.data.data[0];
            setSelectedHomeId(singleHome.id);
            localStorage.setItem("selectedHome", JSON.stringify(singleHome));
            setIsHomeModalOpen(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        localStorage.removeItem("userData");
        navigate("/");
        // Handle error appropriately (e.g., redirect to login if token is invalid)
      }
    };

    fetchUserData();
  }, [navigate]);
  const storedHome = localStorage.getItem("selectedHome");

  if (storedHome) {
    const homeData = JSON.parse(storedHome);
    if (homeData.members && homeData.members.length > 0) {
      userRole = homeData.members[0].role; // Extract the role
      userId = homeData.members[0].user?.id; // Extract the user ID
    }
  }
  const handleHomeSelection = (home) => {
    setSelectedHomeId(home.id);
    localStorage.setItem("selectedHome", JSON.stringify(home));
    setIsHomeModalOpen(false);
  };
  const handleMyTasksClick = () => {
    if (userRole === "Staff" && userId) {
      navigate(`/my-tasks/${userId}`); // Redirect to /my-tasks/:id for Staff
    } else {
      navigate("/staff-tasks"); // Default redirection
    }
  };
  return (
    <div className="dashboard-container">
      {contextHolder}

      {loading ? ( // Show spinner during initial loading
        <div className="loading-screen">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                }}
                spin
              />
            }
          />
        </div>
      ) : isHomeModalOpen ? ( // Show modal if homes need selection
        <div className="home-selection-modal">
          <div className="modal-content">
            <h2>Select Your Home</h2>
            <div className="home-list">
              {homes.map((home) => {
                const nameParts = home.name.split(" ");
                const initials =
                  nameParts.length > 1
                    ? nameParts[0][0] + nameParts[1][0]
                    : nameParts[0][0];
                return (
                  <div
                    key={home.id}
                    className="home-card"
                    onClick={() => handleHomeSelection(home)}
                  >
                    {home.imageUrl ? (
                      <img
                        src={home.imageUrl}
                        alt={home.name}
                        className="home-image"
                      />
                    ) : (
                      <div className="home-initials text-lg tracking-wider font-semibold w-[80px] h-[80px] flex justify-center items-center rounded-full mx-auto bg-slate-100">
                        {initials}
                      </div>
                    )}
                    <p>{home.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        selectedHomeId && ( // Render main dashboard content if a home is selected
          <div className="cards-outer-container flex justify-center">
            <div className="cards-scroll-container">
              <div
                className="card"
                onClick={() => {
                  handleMyTasksClick();
                }}
              >
                <h2>
                  My
                  <br />
                  Tasks
                </h2>
                <button className="continue-btn">Continue</button>
              </div>
              <div
                className="card"
                onClick={() => {
                  // navigate("/plans-payments");
                  openNotification("top", message, 0, "400px");
                }}
              >
                <h2>
                  Plans
                  <br />
                  and
                  <br />
                  Payments
                </h2>
                <button className="continue-btn">Continue</button>
              </div>
            </div>
          </div>
        )
      )}
      {!loading &&
        homes &&
        homes.length === 0 && ( // Check if the homes array is empty
          <p className="no-homes-message py-8">There is no home available.</p>
        )}
    </div>
  );
};

export default Home;
