export const taskSchedule = [
    { value: "DOES_NOT_REPEAT", label: "Does Not Repeat" },
    { value: "EVERY_WORK_DAY", label: "Every Work Day" },
    { value: "DAILY", label: "Daily" },
    { value: "WEEKLY", label: "Weekly" },
    { value: "MONTHLY", label: "Monthly" },
  { value: "YEARLY", label: "Yearly" },
  { value: "CUSTOM", label: "Custom" },]
export const frequency = [
    { value: "DAY", label: "DAY" },
    { value: "WEEK", label: "WEEK" },
    { value: "MONTH", label: "MONTH" },
    { value: "YEAR", label: "YEAR" },
    { value: "EVERY_WORK_DAY", label: "EVERY WORK DAY" },
]

export const frequencyMapping = {
  'DAILY': 'DAY',
  'WEEKLY': 'WEEK',  // Note: WEEKLY stays as WEEKLY
  'MONTHLY': 'MONTH',
  'YEARLY': 'YEAR',
  'EVERY_WORK_DAY': 'EVERY_WORK_DAY'  // Maps to WEEK
};

// Mapping for taskSchedule.frequency -> taskFrequency
export const reverseFrequencyMapping = {
  'DAY': 'DAILY',
  'WEEK': 'WEEKLY',
  'MONTH': 'MONTHLY',
  'YEAR': 'YEARLY',
  "EVERY WORK DAY":"EVERY_WORK_DAY"// WEEK maps back to EVERY_WORK_DAY
};
export const daysOfWeek = [
  { short: "M", full: "MONDAY" },
  { short: "T", full: "TUESDAY" },
  { short: "W", full: "WEDNESDAY" },
  { short: "T", full: "THURSDAY" },
  { short: "F", full: "FRIDAY" },
  { short: "S", full: "SATURDAY" },
  { short: "S", full: "SUNDAY" },
];

export const NthDaysOfWeek = [
  {value:1,label:"FIRST"},
  {value:2,label:"SECOND"},
  {value:3,label:"THIRD"},
  {value:4,label:"FOURTH"},
  {value:5,label:"LAST"}
]

export const WeekDays = [
  { value: "MONDAY", label: "MONDAY" },
  { value: "TUESDAY", label: "TUESDAY" },
  { value: "WEDNESDAY", label: "WEDNESDAY" },
  { value: "THURSDAY", label: "THURSDAY" },
  { value: "FRIDAY", label: "FRIDAY" },
  { value: "SATURDAY", label: "SATURDAY" },
  { value: "SUNDAY", label: "SUNDAY" },
]

export const MonthNames = [
  { value: "JANUARY", label: "JANUARY" },
  { value: "FEBRUARY", label: "FEBRUARY" },
  { value: "MARCH", label: "MARCH" },
  { value: "APRIL", label: "APRIL" },
  { value: "MAY", label: "MAY" },
  { value: "JUNE", label: "JUNE" },
  { value: "JULY", label: "JULY" },
  { value: "AUGUST", label: "AUGUST" },
  { value: "SEPTEMBER", label: "SEPTEMBER" },
  { value: "OCTOBER", label: "OCTOBER" },
  { value: "NOVEMBER", label: "NOVEMBER" },
  { value: "DECEMBER", label: "DECEMBER" },
];
