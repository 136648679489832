import React, { useEffect, useState } from "react";
import "../../styles/AddTask.css";
import { useNavigate } from "react-router-dom";
import { CgDanger } from "react-icons/cg";

import axios from "axios";
import {
  DatePicker,
  Input,
  Select,
  TimePicker,
  Upload,
  notification,
  message,
  Button,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import {
  frequencyMapping,
  NthDaysOfWeek,
  reverseFrequencyMapping,
  taskSchedule,
  WeekDays,
} from "../../utils/taskSchedule";
import {
  Custom,
  Daily,
  EveryWorkDay,
  Monthly,
  Weekly,
  Yearly,
} from "./Frequency";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as DownArrow } from "../../images/down-arrow.svg";
import { ReactComponent as Calender } from "../../images/calendar-icon.svg";
import { ReactComponent as TimeIcon } from "../../images/time-icon.svg";
import TimeConflictModal, { ProcedureModal } from "../../utils/genericComponent";
import moment from "moment";

const AddTask = () => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const openNotification = (placement, msg,status) => {
    {
      status === 200?api.success({
        description: msg,
        placement
      }):api.error({
        description: msg,
        placement
      });
    }
   
  };
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [procedure, setProcedure] = useState([]);
  const [fileLists, setFileLists] = useState({});
  const [staffList, setStaffList] = useState([]);
  const [taskErrorMsg, setTaskErrorMsg] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [scheduleText, setScheduleText] = useState({});
  const [errorTasks, setErrorTasks] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", body: "" });
  const [radioSelections, setRadioSelections] = useState({});
  const [selectedHome, setSelectedHome] = useState(
    JSON.parse(localStorage.getItem("selectedHome")) || null
  );
  const [isModalVisible, setIsModalVisible] = useState(false); // For modal visibility
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </button>
  );
  const handleCancel = () => {
    setOpen(false);
    setModalContent({ title: "", body: "" });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Close Modal
  const handleProcedureCancel = () => {
    setIsModalVisible(false);
  };


  // const handleRedirect = () => {
  //   setLoading(true);
  //   setOpenTask(true)
  //   setLoading(false);
  // };
  const [taskData, setTaskData] = useState([
    {
      homeId: null,
      title: "",
      description: "",
      procedureId: null,
      procedureSteps: [],
      assignedToUserId: null,
      assignedToSubUserId: null,
      taskFrequency: "DOES_NOT_REPEAT",
      locationAddress: "",
      latitude: null,
      longitude: null,
      taskPhotos: [],
      taskSchedule: [
        {
          startDate: dayjs().format("DD-MM-YYYY"), // Date string, required
          endDate: null, // Date string, optional, nullable
          startTime: null, // Optional, nullable number
          endTime: null, // Optional, nullable number
          interval: 1, // Optional number, default 1
          onWeekDays: ["MONDAY"], // Optional array of enums, default []
          onMonths: ["JANUARY"], // Optional array of enums, max 1, default []
          onDays: [1], // Optional array of numbers, max 1, default []
          onNthDaysOfWeek: [1], // Optional array of numbers, max 1, default []
          frequency: null,
        },
      ],
      isCompletionRequired: false,
      isCompletionPictureRequired: false,
    },
    {
      homeId: null,
      title: "",
      description: "",
      procedureId: null,
      procedureSteps: [],
      assignedToUserId: null,
      assignedToSubUserId: null,
      taskFrequency: "DOES_NOT_REPEAT",
      locationAddress: "",
      latitude: null,
      longitude: null,
      taskPhotos: [],
      taskSchedule: [
        {
          startDate: dayjs().format("DD-MM-YYYY"), // Date string, required
          endDate: null, // Date string, optional, nullable
          startTime: null, // Optional, nullable number
          endTime: null, // Optional, nullable number
          interval: 1, // Optional number, default 1
          onWeekDays: ["MONDAY"], // Optional array of enums, default []
          onMonths: ["JANUARY"], // Optional array of enums, max 1, default []
          onDays: [1], // Optional array of numbers, max 1, default []
          onNthDaysOfWeek: [1], // Optional array of numbers, max 1, default []
          frequency: null,
        },
      ],
      isCompletionRequired: false,
      isCompletionPictureRequired: false,
    },
  ]);

  const fetchProcedure = async (homeId) => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}procedures?homeId=${homeId}`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );
    setProcedure(response.data.data);
  };

  const fetchStaff = async (homeId) => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}homes/${homeId}/members?role=Staff`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );
    setStaffList(response.data.data);
  };

  // commenting for test
  // useEffect(() => {
  //   // Fetch selected home from localStorage

  //   if (selectedHome) {
  //     setTaskData((prevTaskData) => ({
  //       ...prevTaskData,
  //       homeId: selectedHome.id, // Set homeId dynamically
  //     }));
  //   } else {
  //     console.error("No home selected!");
  //     navigate("/");
  //     return;
  //     // Redirect user to select a home if none is found
  //   }
  // }, [navigate, selectedHome]);

  useEffect(() => {
    if (selectedHome) {
      setTaskData((prevTaskData) =>
        prevTaskData.map((task) => ({
          ...task,
          homeId: selectedHome.id, // Set homeId dynamically for each task
        }))
      );
    } else {
      console.error("No home selected!");
      navigate("/");
      return; // Redirect user to select a home if none is found
    }
  }, [navigate, selectedHome]);

  useEffect(() => {
    const updateHomeData = async () => {
      if (taskData[0].homeId) {
        setLoading(true); // Start loading

        try {
          await Promise.all([
            fetchProcedure(taskData[0]?.homeId),
            fetchStaff(taskData[0]?.homeId),
          ]);
        } catch (error) {
          console.error("Error updating home data:", error);
        } finally {
          setLoading(false); // End loading
        }
      }
    };

    updateHomeData();
  }, [taskData[0].homeId]);

  const updateScheduleText = (index) => {
    if (!taskData.length) {
      setScheduleText({}); // Handle empty taskData
      return;
    }

    const task = taskData[index];
    // Assuming you're working with the first task
    const schedule = task.taskSchedule?.[0]; // Assuming single schedule
    if (!schedule) {
      setScheduleText((prev) => ({ ...prev, [index]: "" })); // Handle missing schedule
      return;
    }

    const frequency = schedule.frequency; // e.g., Monthly, Yearly, etc.
    const onDay = schedule.onDays?.[0];
    const nthDay = schedule.onNthDaysOfWeek?.[0];
    const weekDay = schedule.onWeekDays?.[0];
    const weekDays = schedule.onWeekDays || [];
    const interval = schedule.interval;
    const month = schedule.onMonths?.[0];
    const formattedDate = moment(schedule?.startDate, 'DD-MM-YYYY').format('DD MMM YYYY');
        const formattedEndDate =schedule?.endDate? moment(schedule?.endDate, 'DD-MM-YYYY').format('DD MMM YYYY'):"";
    let newScheduleText = "";

    const selectedRadio = radioSelections[index] || "On Day";
    if(frequency === "DAY"){
     newScheduleText = `Occurs every ${interval ===1 ?  "": interval} day starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`;

    }
    if (frequency === "EVERY_WORK_DAY") {
      newScheduleText = `Occurs every work day ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`;

    }
    if (frequency === "WEEK") {
      const weekDaysText = weekDays
      .map((day) => {
        return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
      })
      .join(", ");
    newScheduleText = `Occurs every ${interval ===1 ?  "": interval} week on ${weekDaysText} starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`;
    }

    if (selectedRadio === "On Day") {
      if (frequency === "MONTH") {
        newScheduleText = `Occurs on ${`day ${onDay} of every ${interval ===1 ?  "": interval} month`} starting from ${formattedDate} ${formattedEndDate ? ` to ${formattedEndDate}` : ''}`;

      } else if (frequency === "YEAR") {
        newScheduleText = `Occurs  every ${interval ===1 ?  "": interval} year on day ${onDay} of ${month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()} starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`;

      }
    } else if (selectedRadio === "On The") {
      const nthDayText =
        NthDaysOfWeek.find((item) => item.value === nthDay)?.label || "first";
      const weekDayText =
        WeekDays.find((item) => item.value === weekDay)?.label || "MONDAY";

      if (frequency === "MONTH") {
        newScheduleText = `Occurs on ${ `the ${nthDay} ${weekDays} of every ${interval ===1 ?  "": interval} month`} starting from ${formattedDate} ${formattedEndDate ? ` to ${formattedEndDate}` : ''}`;

      } else if (frequency === "YEAR") {
        newScheduleText = `Occurs  every ${interval ===1 ?  "": interval} year on the ${nthDay} ${weekDays} of ${month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()}   starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`;

      }
    }
    if(frequency === "CUSTOM"){
       const formattedDate = moment(task?.taskSchedule[0].startDate,'DD-MM-YYYY').format(
              "DD MMM, YYYY"
            );
            const formattedEndDate = task?.taskSchedule[task?.taskSchedule.length - 1]
              .startDate
              ? moment(
                  task?.taskSchedule[task?.taskSchedule.length - 1].startDate,'DD-MM-YYYY'
                ).format("DD MMM, YYYY")
              : "";
            newScheduleText = `Occurs on ${formattedDate} ${
              formattedEndDate ? `, ${formattedEndDate}` : ``
            }`;
    }
    setScheduleText((prev) => ({
      ...prev,
      [index]: newScheduleText, // Map text to the task index
    }));
  };

  // useEffect(() => {
  //   updateScheduleText();
  // }, [radioSelections]);

  const handleInputChange = (
    value,
    name,
    index,
    nestedField,
    scheduleIndex = 0
  ) => {
    setTaskData((prev) => {
      const updatedData = [...prev]; // Create a copy of the taskData array

      // Ensure that the index exists within taskData
      if (index !== null && updatedData[index]) {
        const task = updatedData[index]; // Access the specific task by index

        if (name === "procedureId") {
          const selectedProcedure = procedure.find((item) => item.id === value);
          const stepsAsText = selectedProcedure?.steps || [];

          // Update the procedureSteps of the specific task
          task.procedureSteps = stepsAsText;
        }

        if (name === "taskFrequency") {
  
   
            
            setRadioSelections((prev) => ({
              ...prev,
              [index]: "On Day",
            }));
          
          task.taskSchedule.forEach((schedule) => {
            schedule.onDays = [1];
            schedule.onMonths = ["JANUARY"];
            schedule.onNthDaysOfWeek = [1];
            schedule.onWeekDays = ["MONDAY"];
          });
          if (value === "EVERY_WORK_DAY") {
            if (!task.assignedToUserId || task.assignedToUserId.length === 0) {
              api.info({
                description:
                  "Please select an assigned user before setting frequency to 'EVERY_WORK_DAY'.",
                placement: "top",
                duration: 3,
              });
              return prev; // Return the previous state without updating
            }

            const assignedStaff = staffList.find(
              (staff) => staff.userId === task.assignedToUserId
            );

            if (assignedStaff && assignedStaff.staffWorkHour?.length > 0) {
              const workDays = assignedStaff.staffWorkHour[0]?.workDays || [];
              task.taskSchedule[0].onWeekDays = workDays; // Update workDays
            }
          }
          task.taskFrequency = value;

          const mappedFrequency = frequencyMapping[value] || value;
          task.taskSchedule[0].frequency = mappedFrequency;
        } else if (
          name === "taskSchedule" &&
          nestedField === "frequency" &&
          index !== null
        ) {
          task.taskSchedule[scheduleIndex][nestedField] = value;
          const mappedFrequency = reverseFrequencyMapping[value] || value;

          task.taskFrequency = mappedFrequency;
        } else if (
          name === "assignedToUserId" &&
          value === task.assignedToSubUserId
        ) {
          task[name] = value;
          task.assignedToSubUserId = null;
        } else if (
          name === "assignedToSubUserId" &&
          value === task.assignedToUserId
        ) {
          return updatedData;
        }

        if (index !== null && nestedField) {
          // Always work with the first element of taskSchedule

          if (nestedField === "startTime" || nestedField === "endTime") {
            const [hours, minutes] = value.split(":");
            const timeAsNumber =
              parseInt(hours, 10) * 100 + parseInt(minutes, 10);

            if (task.taskSchedule[scheduleIndex]) {
              task.taskSchedule[scheduleIndex][nestedField] = timeAsNumber;
            } else {
              console.error(
                `taskSchedule[${scheduleIndex}] is undefined for taskIndex: ${index}`
              );
            }
          } else {
            if (task.taskSchedule[scheduleIndex]) {
              task.taskSchedule[scheduleIndex][nestedField] = value;
            } else {
              console.error(
                `taskSchedule[${scheduleIndex}] is undefined for taskIndex: ${index}`
              );
            }
          }
        } else {
          task[name] = value;
        }
      } else {
        // Handle the case where the index is invalid or undefined
        console.error("Invalid index:", index);
      }

      return updatedData; // Return the updated array with the modified task
    });

    // updateScheduleText();
  };

  const addNewSchedule = (taskIndex) => {
    setTaskData((prevState) =>
      prevState.map((task, index) =>
        index === taskIndex
          ? {
              ...task,
              taskSchedule: [
                ...task.taskSchedule,
                {
                  startDate: dayjs().format("DD-MM-YYYY"),
                  endDate: null,
                  startTime: null,
                  endTime: null,
                },
              ],
            }
          : task
      )
    );
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();
    // Add your submission logic here
    let successfulTasks = [];
    let failedTasks = [];
    try {
      const userData = localStorage.getItem("userData");
      const userDataObj = JSON.parse(userData);

      for (let i = 0; i < taskData.length; i++) {
        const task = taskData[i];
        // Deep copy the task object to avoid mutating the original state
        const taskToSend = JSON.parse(JSON.stringify(task));

        
        if (!taskToSend.title || !taskToSend.assignedToUserId) {
          console.error(`Task ${i} is missing required fields.`);
          failedTasks.push(i);
          openNotification(
            "top",
            `Task ${i + 1} is missing a title or an assigned user.`
          );
          continue; // Skip this task and move to the next one
        }
        if(!taskToSend.taskSchedule[0].interval || !taskToSend.taskSchedule[0].interval ===""){
          console.error(`Task ${i} is missing required fields.`);
          failedTasks.push(i);
          openNotification(
            "top",
            `Task ${i + 1} is missing a Interval.`
          );
          continue;
        }

        // Adjust the task data based on its frequency
        if (taskToSend.taskFrequency === "CUSTOM") {
          taskToSend.taskSchedule.forEach((schedule) => {
            schedule.onDays = [];
            schedule.onMonths = [];
            schedule.onNthDaysOfWeek = [];
            schedule.onWeekDays = [];
            schedule.frequency = "CUSTOM";
          });
        }

        if (taskToSend.taskFrequency === "MONTHLY") {
          if ((radioSelections[i]) === "On Day") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onNthDaysOfWeek = [];
              schedule.onWeekDays = [];
              schedule.onMonths = [];
            });
          } else if ((radioSelections[i]) === "On The") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onDays = [];
              schedule.onMonths = [];
            });
          }
        }

        if (taskToSend.taskFrequency === "YEARLY") {
          if ((radioSelections[i]) === "On Day") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onWeekDays = [];
              schedule.onNthDaysOfWeek = [];
            });
          } else if ((radioSelections[i]) === "On The") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onDays = [];
            });
          }
        }

        if (
          taskToSend.taskFrequency === "WEEKLY" ||
          taskToSend.taskFrequency === "EVERY_WORK_DAY"
        ) {
          taskToSend.taskSchedule = taskToSend.taskSchedule.map((schedule) => ({
            ...schedule,
            onDays: [],
            onMonths: [],
            onNthDaysOfWeek: [],
          }));
        }
        if (taskToSend.taskFrequency === "DAILY") {
          taskToSend.taskSchedule = taskToSend.taskSchedule.map((schedule) => ({
            ...schedule,
            onDays: [],
            onMonths: [],
            onNthDaysOfWeek: [],
            onWeekDays: [],
          }));
        }

        if (taskToSend.taskFrequency === "DOES_NOT_REPEAT") {
          taskToSend.taskSchedule = taskToSend.taskSchedule.map((schedule) => ({
            ...schedule,
            onDays: [],
            onMonths: [],
            onNthDaysOfWeek: [],
            onWeekDays: [],
          }));
        }
        // Send the task object to the API


        try {
          // Send the task object to the API

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}tasks`, // API endpoint
            taskToSend, // Data to send in the request body
            {
              headers: {
                Authorization: `Bearer ${userDataObj.token}`, // Authorization header
              },
            }
          );
          openNotification("top", `Task ${i + 1} Created Successfully`,200);

          successfulTasks.push(i);
        } catch (taskError) {
          if (
            taskError?.response?.data?.data?.error?.includes(
              "Task timings conflicting"
            )
          ) {
            const conflictingTaskId =
              taskError.response.data.data.conflictingTaskIds[0];
            const staffId = taskToSend?.assignedToUserId;
            setModalContent({
              title: "Task Timing Conflict",
              body: `A conflict has been detected with another task (ID: ${conflictingTaskId}). Please review and resolve the conflict.`,
              conflictingTaskId,
              staffId,
            });
            setOpen(true);
          } else {
            console.error(
              `Error submitting task ${taskToSend.title}:`,
              taskError
            );
            failedTasks.push(i);
            // Notify the user about the specific task error
            openNotification(
              "top",
              taskError?.response?.data?.error ||
                taskError?.response?.data?.data?.error ||
                `Failed to submit task ${taskToSend.title}.`
            );
          }
        }
      }
      setFileLists([])
      setTaskData((prevData) =>
        prevData.map((task, index) => {
          if (successfulTasks.includes(index)) {
            // Reset successful tasks
            return {
              homeId: selectedHome?.id,
              title: "",
              description: "",
              procedureId: null,
              procedureSteps: [],
              assignedToUserId: null,
              assignedToSubUserId: null,
              taskFrequency: "DOES_NOT_REPEAT",
              locationAddress: "",
              latitude: null,
              longitude: null,
              taskPhotos: [],
              taskSchedule: [
                {
                  startDate: dayjs().format("DD-MM-YYYY"),
                  endDate: null,
                  startTime: null,
                  endTime: null,
                  interval: 1,
                  onWeekDays: ["MONDAY"],
                  onMonths: ["JANUARY"],
                  onDays: [1],
                  onNthDaysOfWeek: [1],
                  frequency: null,
                },
              ],
              isCompletionRequired: false,
              isCompletionPictureRequired: false,
            };
          } else {
            // Keep failed tasks as they are
            return task;
          }
        })
      );
      setErrorTasks(failedTasks);
      if (failedTasks.length > 0) {
        // Scroll to the first failed task

        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        if (action === "create") {
          // Redirect to "my-list" page
          setTimeout(() => {
            navigate("/staff-tasks");
          }, 2000);
        } else if (action === "addMore") {
          
          // Reset all tasks for new entry
          setTimeout(() => {
            setFileLists([])
            setTaskData((prevData) =>
              prevData.map(() => ({
                homeId: selectedHome?.id,
                title: "",
                description: "",
                procedureId: null,
                procedureSteps: [],
                assignedToUserId: null,
                assignedToSubUserId: null,
                taskFrequency: "DOES_NOT_REPEAT",
                locationAddress: "",
                latitude: null,
                longitude: null,
                taskPhotos: [],
                taskSchedule: [
                  {
                    startDate: dayjs().format("DD-MM-YYYY"),
                    endDate: null,
                    startTime: null,
                    endTime: null,
                    interval: 1,
                    onWeekDays: ["MONDAY"],
                    onMonths: ["JANUARY"],
                    onDays: [1],
                    onNthDaysOfWeek: [1],
                    frequency: null,
                  },
                ],
                isCompletionRequired: false,
                isCompletionPictureRequired: false,
              }))
            );
          }, 2000);
          
        }
      }
    } catch (error) {
    
      
      setTaskErrorMsg(
        error?.response?.data?.error || error?.response?.data?.data?.error
      );
      openNotification(
        "top",
        error?.response?.data?.data?.error ||
          error?.response?.data?.error ||
          "Something went wrong."
      );
    }
  };

  // const validateFields = () => {
  //   let isValid = true;

  //   if (!taskData.title.trim()) {
  //     openNotification("top", "Enter a valid title.");
  //     isValid = false;
  //   } else if (!taskData.assignedToUserId) {
  //     openNotification("top", "Select an assignee.");
  //     isValid = false;
  //   }

  //   return isValid;
  // };

   // Store fileLists per task

  const handleChange =
    (index) =>
    ({ fileList: newFileList }) => {
      // Update fileList for the specific task
      setFileLists((prev) => ({
        ...prev,
        [index]: newFileList,
      }));
    };

  const customUpload = async ({ file, onSuccess, onError, filename }) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const userData = localStorage.getItem("userData");
      const userDataObj = JSON.parse(userData);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}resources/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userDataObj.token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      message.success("File uploaded successfully!");

      // Get task index from filename or pass it explicitly
      const taskIndex = filename; // If you pass the task index from the Upload component

      // Update taskPhotos for the specific task
      setTaskData((prevTaskData) => {
        const updatedTaskData = [...prevTaskData];
        updatedTaskData[taskIndex] = {
          ...updatedTaskData[taskIndex],
          taskPhotos: [
            ...updatedTaskData[taskIndex].taskPhotos,
            data.data?.imageUrl,
          ], // Assuming `data.fileUrl` contains the image URL
        };
        return updatedTaskData;
      });

      onSuccess(data);
    } catch (error) {
      message.error("Upload failed. Please try again.");
      onError(error);
    }
  };

  const handleRemove = (file, taskIndex) => {
    setTaskData((prev) => {
      const updatedTaskData = [...prev];
      updatedTaskData[taskIndex] = {
        ...updatedTaskData[taskIndex],
        taskPhotos: updatedTaskData[taskIndex].taskPhotos.filter(
          (photo) => photo.uid !== file.uid
        ),
      };
      return updatedTaskData;
    });
  };
  const handleRadioChange = (value, index) => {
    setRadioSelections((prev) => ({
      ...prev,
      [index]: value,
    }));
    updateScheduleText(index);
  };

  // useEffect(() => {
  //   taskData.forEach((task, index) => {
  //     if (task?.taskFrequency === "YEARLY") {
  //       setRadioSelections((prev) => ({
  //         ...prev,
  //         [index]: "On Day",
  //       }));
  //     }
  //   });
    
  // }, []);

  useEffect(() => {
    taskData.forEach((task, index) => {
      updateScheduleText(index);
    });
  }, [taskData, radioSelections]);


  return (
    <div
      className="relative task-div  pt-[8px] px-[8px] overflow-auto"
      style={{ height: "calc(100vh - 75px)" }}
    >
      {contextHolder}
      <TimeConflictModal
        open={open}
        modalContent={modalContent}
        onCancel={handleCancel}
        loading={loading}
      />
      <ProcedureModal
      open ={isModalVisible}
      setIsModalVisible = {setIsModalVisible}
      onCancel = {handleProcedureCancel}
      selectedHome = {selectedHome}
      fetchProcedure = {fetchProcedure}
      openNotification={openNotification}
      />
      <div className="flex flex-col gap-2 bg-[#F2F2F2] px-2 py-2 rounded-lg">
        {taskData?.map((task, index) => {
          return (
            <div
              id={`task-${index}`}
              key={index}
              className={`w-full add-task-form m-auto bg-white rounded-lg  ${
                errorTasks.includes(index) ? "task-error" : ""
              }
            `}
            >
              <div className="task-details">
                <h2 className="text-left mb-[10px] text-[14px] font-light tracking-[1.1px]">
                  TASK DETAILS
                </h2>
                <div className="flex  flex-col gap-2">
                  {/* <div className="flex gap-4 ">
                  <div className="input-group">
                    <label className=" text-left text-[14px] font-medium">
                      Task Title*
                    </label>
                    <Input
                      className="w-[100%]"
                      size="large"
                      required
                      variant="filled"
                      type="text"
                      name="title"
                      value={task.title}
                      onChange={(e) => {
                        handleInputChange(e.target.value, e.target.name, index);
                      }}
                      placeholder="Type here..."
                      maxLength={200}
                      showCount
                    />
                  </div>

                  <div className="input-group">
                    <label className="text-left text-[14px] font-bold">
                      Procedure
                    </label>
                    <Select
                      suffixIcon={<DownArrow />}
                      size="large"
                      variant="filled"
                      style={{ width: "200px" }}
                      placeholder="Select Procedure"
                      value={task.procedureId}
                      onChange={(value) =>
                        handleInputChange(value, "procedureId", index)
                      }
                    >
                      {procedure.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div> */}
                  <div className="flex gap-[10px]">
                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className=" text-left text-[12px] font-medium text-[#262626]">
                          Task Title<span className="text-red-600">*</span>
                        </label>
                        <Input
                          className="w-[100%] h-[30px]"
                          // size="middle"
                          required
                          variant="filled"
                          type="text"
                          name="title"
                          value={task.title}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.value,
                              e.target.name,
                              index
                            );
                          }}
                          placeholder="Type here..."
                          maxLength={200}
                          showCount
                        />
                      </div>
                    </div>
                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className="text-left text-[12px] font-bold text-[#262626]">
                          Procedure
                        </label>
                        <div className="flex items-center w-[100%]">
                        <Select
                          suffixIcon={
                            <DownArrow style={{ height: 14, width: 14 }} />
                          }
                          // size="middle"
                          className="h-[30px] w-[200px]"
                          variant="filled"
                          // style={{ width: "200px" }}
                          placeholder="Select Procedure"
                          value={task.procedureId}
                          onChange={(value) =>
                            handleInputChange(value, "procedureId", index)
                          }
                        >
                          {procedure.map((item, index) => (
                            <Select.Option key={index} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <Button
                       color="default" variant="filled"
                          icon={<PlusOutlined />}
                          onClick={showModal}
                          style={{ marginLeft: 10 }}
                        >
                         
                        </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col text-left w-[70%]">
                    <label className=" text-left text-[12px] font-medium text-[#262626]">
                      Task Description
                    </label>
                    <div
                      style={{
                        borderRadius: "4px",
                        padding: "2px 5px",
                        // height: "148px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#0000000a",
                      }}
                    >
                      <div
                        style={{
                          // flex: "1",
                          overflowY: "auto",
                          marginBottom: "0px",
                          height: "25px",
                        }}
                        className="custom-scrollbar "
                      >
                        {task.procedureSteps.length > 0 ? (
                          task.procedureSteps.map((step, index) => (
                            <p
                              key={index}
                              style={{
                                margin: "0px",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                fontSize: "14px",
                              }}
                            >
                              {index + 1}.{step}
                            </p>
                          ))
                        ) : (
                          <p style={{ color: "#999" }}></p>
                        )}
                      </div>

                      <div className="">
                        <label className="text-left text-[12px] font-medium text-[#262626]">
                          Additional Notes
                        </label>
                        <TextArea
                          name="description"
                          className="custom-scrollbar"
                          style={{
                            backgroundColor: "#0000000a",
                            width: "100%",
                            border: "none",
                            height: "60px",
                          }}
                          // size="middle"
                          // autoSize={{ minRows: 1.5, maxRows: 5 }}
                          value={task.description}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.value,
                              e.target.name,
                              index
                            )
                          }
                          placeholder="Write additional notes here..."
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-[10px]">
                    <div className="w-[50%]">
                      <div className="input-group ">
                        <label className="text-left text-[12px] font-bold text-[#262626]">
                          Assign To<span className="text-red-600">*</span>
                        </label>
                        {staffList.length > 0 ? (
        <Select
          suffixIcon={<DownArrow style={{ height: 14, width: 14 }} />}
          className="w-[100%] h-[30px]"
          variant="filled"
          name="assignee"
          value={task.assignedToUserId}
          onChange={(value) =>
            handleInputChange(value, "assignedToUserId", index)
          }
          placeholder="Select assignee"
        >
          {staffList
            .filter((item) => item.isEnabled)
            .map((item, index) => (
              <Select.Option key={index} value={item.userId}>
                {item.user.firstName} {item.user.lastName}
              </Select.Option>
            ))}
        </Select>
      ) : (
        <div className="text-red-500 text-[11px]">There are no Staff Member currently</div>
      )}
                      </div>
                    </div>

                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className="text-left text-[12px] text-for-this  font-bold text-[#262626]">
                          Assign To in case of Absence
                        </label>
                        <Select
                          suffixIcon={
                            <DownArrow style={{ height: 14, width: 14 }} />
                          }
                          // size="large"
                          className="w-[100%] h-[30px]"
                          variant="filled"
                          name="backupAssignee"
                          value={task.assignedToSubUserId}
                          onChange={(value) =>
                            handleInputChange(
                              value,
                              "assignedToSubUserId",
                              index
                            )
                          }
                          placeholder="Select backup assignee"
                          disabled={staffList.filter(
                            (item) => item.userId !== task.assignedToUserId && item.isEnabled
                          ).length === 0}
                        >
                          {staffList
                            .filter(
                              (item) =>
                                item.userId !== task.assignedToUserId &&
                                item.isEnabled
                            )
                            .map((item, index) => (
                              <Select.Option key={index} value={item.userId}>
                                {item.user.firstName} {item.user.lastName}
                              </Select.Option>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-[1px] bg-[#D2D4D5]"></div>

              <div className="task-schedule">
                <h2 className="text-left  mb-[10px]  text-[14px] font-light tracking-[1.1px]">
                  TASK SCHEDULE
                </h2>
                <div className="flex flex-col gap-2 ">
                  {/* we have heighy on upper div */}
                  <div className="flex gap-[10px]">
                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className="text-left text-[12px] font-bold text-[#262626]">
                          Set Frequency
                        </label>
                        <Select
                          suffixIcon={
                            <DownArrow style={{ height: 14, width: 14 }} />
                          }
                          // size="large"
                          className="h-[30px]"
                          variant="filled"
                          name="taskFrequency"
                          value={task.taskFrequency}
                          onChange={(value) => {
                            handleInputChange(value, "taskFrequency", index);
                            updateScheduleText(index);
                          }}
                        >
                          {taskSchedule.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="w-[50%]">
                      {task?.taskFrequency === "DOES_NOT_REPEAT" && (
                        <div className="input-group">
                          <label className="text-left text-[12px] font-bold text-[#262626]">
                            Choose Date<span className="text-red-600">*</span>
                          </label>
                          <DatePicker
                            allowClear={false}
                            defaultValue={dayjs()}
                            suffixIcon={
                              <Calender style={{ height: 14, width: 14 }} />
                            }
                            // size="large"
                            className="h-[30px]"
                            variant="filled"
                            format="DD-MM-YYYY" // Pass format as a string
                            value={
                              task?.taskSchedule[0]?.startDate
                                ? dayjs(
                                    task?.taskSchedule[0]?.startDate,
                                    "DD-MM-YYYY"
                                  )
                                : dayjs()
                            }
                            onChange={(date, dateString) =>
                              handleInputChange(
                                dateString,
                                "taskSchedule",
                                index,
                                "startDate"
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="py-1 flex flex-col gap-2 max-h-[160px] h-fit w-[100%] custom-scrollbar overflow-auto">
                    {task?.taskFrequency === "EVERY_WORK_DAY" && (
                      <EveryWorkDay
                        taskData={task}
                        handleInputChange={handleInputChange}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency === "DAILY" && (
                      <Daily
                        taskData={task}
                        handleInputChange={handleInputChange}
                        index={index}
                      />
                    )}
                    {task?.taskFrequency === "WEEKLY" && (
                      <Weekly
                        taskData={task}
                        handleInputChange={handleInputChange}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency === "MONTHLY" && (
                      <Monthly
                        taskData={task}
                        handleInputChange={handleInputChange}
                        handleRadioChange={(value) =>
                          handleRadioChange(value, index)
                        }
                        isRadioSelected={radioSelections[index] || "On Day"}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency === "YEARLY" && (
                      <Yearly
                        taskData={task}
                        handleInputChange={handleInputChange}
                        handleRadioChange={(value) =>
                          handleRadioChange(value, index)
                        }
                        isRadioSelected={radioSelections[index] || "On Day"}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency === "CUSTOM" && (
                      <Custom
                        taskData={task}
                        handleInputChange={handleInputChange}
                        addNewSchedule={() => addNewSchedule(index)}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency !== "CUSTOM" && (
                      <div className="flex gap-[10px]">
                        <div className="input-group">
                          <label className="text-left text-[12px] font-bold text-[#262626]">
                            Start Time
                          </label>
                          <TimePicker
                            suffixIcon={<TimeIcon />}
                            // size="large"
                            className="h-[30px]"
                            variant="filled"
                            format="HH:mm"
                            value={
                              task?.taskSchedule[0]?.startTime
                                ? dayjs()
                                    .hour(
                                      Math.floor(
                                        task?.taskSchedule[0]?.startTime / 100
                                      )
                                    )
                                    .minute(
                                      task?.taskSchedule[0]?.startTime % 100
                                    )
                                : null
                            }
                            onChange={(time, timeString) =>
                              handleInputChange(
                                timeString,
                                "taskSchedule",
                                index,
                                "startTime"
                              )
                            }
                          />
                        </div>
                        <div className="input-group">
                          <label className="text-left text-[12px] font-bold text-[#262626]">
                            End Time
                          </label>
                          <TimePicker
                            suffixIcon={<TimeIcon />}
                            // size="large"
                            className="h-[30px]"
                            variant="filled"
                            format="HH:mm"
                            value={
                              task?.taskSchedule[0]?.endTime
                                ? dayjs()
                                    .hour(
                                      Math.floor(
                                        task?.taskSchedule[0]?.endTime / 100
                                      )
                                    )
                                    .minute(
                                      task?.taskSchedule[0]?.endTime % 100
                                    )
                                : null
                            }
                            onChange={(time, timeString) =>
                              handleInputChange(
                                timeString,
                                "taskSchedule",
                                index,
                                "endTime"
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="schedule-text text-gray-600 text-[12px] pt-2 flex m-auto justify-center">
                  {scheduleText[index]}
                </div>
              </div>
              <div className="w-[1px] bg-[#D2D4D5]"></div>

              <div className="additional-details">
                <h2 className="text-left flex items-center gap-2 underline mb-[10px]  text-[14px] font-medium tracking-[1.1px] text-[#205368]">
                  Additional Details <CgDanger size={19} />
                </h2>

                <div className="flex flex-col gap-2">
                  {/* <div className="input-group">
                    <label className="text-left text-[12px] font-bold text-[#262626]">
                      Add Location
                    </label>
                    <Input
                      // size="large"
                      className="h-[30px]"
                      variant="filled"
                      type="text"
                      name="localtion"
                      value={task.locationAddress}
                      onChange={(e) =>
                        handleInputChange(e.target.value, e.target.name, index)
                      }
                      placeholder="Type or Select location"
                    />
                  </div> */}

                  <div className="image-size-foruploadimages">
                    <div className="input-group">
                  <label className="text-left text-[12px] font-bold text-[#262626]">
                      Upload Photos
                    </label>
                    <p className="text-[12px] text-[#616161]">Maximum 5 photos can be uploaded.</p>
                    <Upload
                      className=""
                      action={null} // Disable default upload
                      listType="picture-circle"
                      fileList={fileLists[index] || []}
                      customRequest={(options) =>
                        customUpload({ ...options, filename: index })
                      }
                      showUploadList={{
                        showPreviewIcon: false,
                      }}
                      onChange={handleChange(index)}
                      maxCount={5}
                      onRemove={(file) => handleRemove(file, index)}
                    >
                      {task?.taskPhotos?.length < 5 && (
                        <div clas>{uploadButton}</div>
                      )}
                    </Upload>
                    </div>
                  </div>

                  <div className="checkboxes">
                  {task?.taskFrequency !== "DOES_NOT_REPEAT" &&
                    <label className="flex font-normal text-[12px] flex-row gap-1 text-[#616161]">
                      <Input
                        variant="filled"
                        className="w-auto"
                        type="checkbox"
                        name="requireSkippedCompletion"
                        checked={task?.isCompletionRequired}
                        onChange={(e) =>
                          setTaskData((prevData) =>
                            prevData.map((item, idx) =>
                              idx === index
                                ? {
                                    ...item,
                                    isCompletionRequired: e.target.checked,
                                  }
                                : item
                            )
                          )
                        }
                      />
                      Require completion if skipped
                    </label>
        }
                    <label className="font-normal text-[12px] mt-[2px] flex flex-row  gap-1 text-[#616161]">
                      <Input
                        variant="filled"
                        className="w-auto"
                        type="checkbox"
                        name="requireCompletionPhoto"
                        checked={task?.isCompletionPictureRequired}
                        onChange={(e) =>
                          setTaskData((prevData) =>
                            prevData.map((item, idx) =>
                              idx === index
                                ? {
                                    ...item,
                                    isCompletionPictureRequired:
                                      e.target.checked,
                                  }
                                : item
                            )
                          )
                        }
                      />
                      <span>Require completion photo</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bottom-4 left-1/2 transform mt-[20px] mb-[20px] justify-center flex flex-row gap-5">
        <button
          type="submit"
          onClick={(e) => handleSubmit(e, "create")}
          className="submit-button py-[4px]  text-[16px]"
        >
          Save and finish
        </button>
        <button
          type="submit"
          onClick={(e) => handleSubmit(e, "addMore")}
          className="submit-button py-[4px] text-[16px]"
        >
          Save and add more
        </button>
      </div>
    </div>
  );
};

export default AddTask;
