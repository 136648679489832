import React from "react";

const SkeletonByTaskId = ({ completed }) => {
  const skeletons = Array(3).fill(0);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 px-5">
      {skeletons.map((_, index) => (
        <div
          key={index}
          className="h-[120px] flex flex-col justify-between relative px-3 py-3 max-w-[500px] w-[100%] rounded-xl bg-gray-100 animate-pulse"
        >
          <div className="absolute top-1/2 left-0 w-[4px] h-[70%] bg-gray-300 rounded-full transform -translate-y-1/2"></div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-2 justify-center items-start">
              <div className="h-5 w-32 bg-gray-300 rounded"></div>
              <div className="h-4 w-24 bg-gray-300 rounded"></div>
            </div>
            <div className="flex mt-auto">
              <div className="h-4 w-24 bg-gray-300 rounded"></div>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-2 justify-center items-center">
              <div className="w-[12px] h-[12px] bg-gray-300 rounded-full"></div>
              <div className="h-4 w-20 bg-gray-300 rounded"></div>
            </div>
            <div className="flex gap-3">
              <div className="w-7 h-7 bg-gray-300 rounded-full"></div>
              <div className="w-7 h-7 bg-gray-300 rounded-full"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonByTaskId;
