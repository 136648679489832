import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem("userData"); // Checking if user is logged in
  

  if (!isLoggedIn) {
    // If user is not logged in, redirect them to the login page
    return <Navigate to="/" replace />;
  }

  // If user is logged in, render the protected content (children)
  return children;
};

export default ProtectedRoute;
