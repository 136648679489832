import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import "../../styles/Navbar.css";
import Logo from "../../images/Home_360_logo-darkbckgrnd copy.svg";
import LoginForm from "../Login/LoginForm";
import axios from "axios";
import { Dropdown } from "antd";
import { BsPersonCircle } from "react-icons/bs";
import { IoHome } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { useHome } from "../../utils/homeProvider";

// import logo from '../../assets/logo.png'; // Make sure to add your logo image

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedHome, setSelectedHome] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileAppModalOpen, setIsMobileAppModalOpen] = useState(false);
  const [homes, setHomes] = useState([]);
  const navigate = useNavigate();
  const { updateSelectedHome } = useHome();
  const [selectedLink, setSelectedLink] = useState("");

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("selectedHome");

    // setTimeout(() => {
    window.location.href = "/";
    // }, 300);
  };
  useEffect(() => {
    // Check if the screen width is mobile size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value based on your breakpoint
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // Check for user data in localStorage on component mount
    const storedUserData = localStorage.getItem("userData");
    const storedHome = localStorage.getItem("selectedHome");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
      setIsLoggedIn(true);

      if (storedHome) {
        setSelectedHome(JSON.parse(storedHome)); // Load selected home from storage
      }
      const fetchHomes = async () => {
        try {
          const userDataObj = JSON.parse(localStorage.getItem("userData"));
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/homes`,
            {
              headers: {
                Authorization: `Bearer ${userDataObj.token}`,
              },
            }
          );
          setHomes(response.data.data);
        } catch (error) {
          console.error("Error fetching homes:", error);
        }
      };
      fetchHomes();
    }
  }, [navigate]);

  const handleLogin = (userData) => {
    setUserData(userData);
    setIsLoggedIn(true);
  };

  const handleHomeChange = (homeId) => {
    // Find the selected home object from homes
    const selected = homes.find((home) => home.id === Number(homeId));

 

    if (selected) {
      setSelectedHome(selected);
      localStorage.setItem("selectedHome", JSON.stringify(selected));
      updateSelectedHome(selected);


      if (selected?.members[0]?.role === "Staff") {
        navigate(`/my-tasks/${selected.members[0]?.userId}`);
      } else {
        navigate("/staff-tasks");
      }

    } else {
      return;
    }
  };
  const openModal = () => {
    if (isMobile) {
      setIsMobileAppModalOpen(true);
    } else {
      setIsOpen(true)
    }
  };

  const closeModal = () => {
    setIsOpen(false)
  };

  const closeMobileAppModal = () => {
    setIsMobileAppModalOpen(false);
  };
  // const openModal = () => setIsOpen(true);
  // const closeModal = () => setIsOpen(false);
  const menuItems = homes.map((home) => ({
    key: home.id,
    label: home.name,
  }));

  const menu = {
    items: menuItems,
    onClick: (e) => handleHomeChange(e.key),
  };
  return (
    <header className="header">
      <div
        className="logo cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Logo} alt="Home360" />
      </div>
      {!isLoggedIn ? (
        <div className="header-logo bg-[white]">
          <button onClick={openModal}>
            <a className="login-link text-[#DB6312]">log in</a>
          </button>
        </div>
      ) : (
        <div className="flex items-end mt-auto">
          <div
            onClick={() => {
              setSelectedLink("manage");
              navigate("/manage-account");
            }}
            // to="/manage-account"
            className={`
              text-white cursor-pointer  flex flex-col items-center justify-center gap-[1px] px-3 border-b-[6px] py-[6px]
               ${
                 selectedLink === "manage"
                   ? "border-b-[#DB6412] transition-all duration-300 ease-in-out"
                   : "border-b-[#8d664c] transition-all duration-300 ease-in-out"
               }
              `}
          >
            <IoSettings className="" size={22} />
            <span className="text-[13px]">Manage my account</span>
          </div>

          {selectedHome && (
            <>
              <Dropdown menu={menu} trigger={["click"]}>
                <div
                  onClick={() => setSelectedLink("home")}
                  className={`flex text-white  cursor-pointer flex-col justify-center items-center px-3 border-b-[6px] gap-[1px] py-[6px]
                ${
                  selectedLink === "home"
                    ? "border-b-[#DB6412] transition-all duration-300 ease-in-out"
                    : "border-b-[#8d664c]  transition-all duration-300 ease-in-out"
                }
                `}
                >
                  <IoHome className=" " size={22} />
                  <span className="text-[13px]">{selectedHome.name}</span>
                </div>
              </Dropdown>
            </>
          )}

          {/* <div
            onClick={() => setSelectedLink("user")}
            className={`user-profile cursor-pointer text-white flex flex-col justify-center items-center gap-[1px] px-3 border-b-[6px] py-[6px] ${
              selectedLink === "user"
                ? "border-b-[#DB6412] transition-all duration-300 ease-in-out"
                : "border-b-[#8d664c]  transition-all duration-300 ease-in-out"
            }`}
          >
            <BsPersonCircle className="" size={24} />
            <span className="username text-[14px]">
              {userData?.firstName} {userData?.lastName}
            </span>
          </div> */}
          <>
            <Dropdown
              menu={
                selectedLink === "user"
                  ? {
                      items: [
                        {
                          key: "logout",
                          label: (
                            <div
                              className="text-black cursor-pointer hover:text-gray-700"
                              onClick={handleLogout}
                            >
                              Logout
                            </div>
                          ),
                        },
                      ],
                    }
                  : null
              }
              trigger={["click"]}
            >
              <div
                onClick={() => setSelectedLink("user")}
                className={`user-profile cursor-pointer text-white flex flex-col justify-center items-center gap-[1px] px-3 border-b-[6px] py-[6px] ${
                  selectedLink === "user"
                    ? "border-b-[#DB6412] transition-all duration-300 ease-in-out"
                    : "border-b-[#8d664c] transition-all duration-300 ease-in-out"
                }`}
              >
                <BsPersonCircle className="" size={22} />

                {userData?.firstName ? (
                  <span className="username text-[13px]">
                    {userData?.firstName} {userData?.lastName}
                  </span>
                ) : (
                  <span className="username text-[13px]">Logout</span>
                )}
              </div>
            </Dropdown>
          </>
        </div>
      )}
      {/* Mobile App Modal */}
      {isMobileAppModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center">
        <div
          className="absolute top-[160px] bg-white xl:w-[400px] h-[400px] rounded-lg shadow-lg p-9 mx-[20px] sm:w-[300px]"
        >
          {/* Close Button */}
          <button
              onClick={closeMobileAppModal}
              className="absolute  w-[30px] h-[30px] rounded-full bg-[#294859] text-white -top-3 -right-3"
            >
              ✕
            </button>

            {/* Phone Input */}
            <div className="mt-4">
              <div className="flex items-center rounded-md p-2 bg-[white]">
              <p className="text-[#db6312]">Please use the mobile app for a better experience.</p>
             
              </div>
            </div>
            </div>
            </div>
      )}
      {/* Modal */}
      {isOpen && <LoginForm closeModal={closeModal} onLogin={handleLogin} />}
    </header>
  );
};

export default Navbar;
