import React, { useEffect, useState } from "react";
import "../../styles/HomePage.css";
import Logo from "../../images/Home_360_logo-darkbckgrnd copy.svg";
import image1 from "../../images/Group 2 (1).svg";
import Decorator from "../../images/task-management (1).svg";
import mealPlanning from "../../images/meal.svg";
import provisions from "../../images/nutrition-plan.svg";
import staffManagement from "../../images/management.png";
import playBack from "../../images/playback.svg";
import translate from "../../images/translate.svg";
import calendar from "../../images/calender.svg";
import recipe from "../../images/recipe.svg";
import mealSuggestion from "../../images/meal-suggestion.svg";
import chatIcon from "../../images/chat-icon.svg";
import Footer from "../Footer/Footer";
import PosterImage from "../../images/freestocks-mw6Onwg4frY-unsplash.svg";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import Qr from "../../images/QR.svg";
import axios from "axios";
// import mealPlanning from "../../images/meal-planning.svg";
// import provisions from "../../images/provisions.svg";

const HomePage = () => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (phoneNumber) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}misc/add-to-waitlist`,
          {
            name: userName,
            countryCode: "+91",
            phoneNumber: phoneNumber,
          }
        );

        if (response.data.statusCode == 200) {
          openNotification(
            "top",
            response.data.message,
            response.data.statusCode,
            "400"
          );
          setUserName("");
          setPhoneNumber("");
        }
        setLoading(false);
      } catch (error) {
        openNotification(
          "top",
          "Something Went Wrong, Try Again Later!",
          401,
          "500"
        );
        setLoading(false);
      }
    } else {
      openNotification(
        "top",
        "Please provide Phone number!",
        401,
        "500"
      );
    }
  };

  const isLaunched = !true;

  const message = "Coming Soon";

  const openNotification = (placement, message, status, width) => {
    api.info({
      message: `${message}`,
      placement,
      duration: 5,
      style: {
        width: width,
      },
      className: `${
        status === 200
          ? "custom-notification-success"
          : status === 401
          ? "custom-notification-error"
          : ""
      }`,
    });
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("userData"); // Checking if user is logged in

    if (isLoggedIn) {
      // If user is not logged in, redirect them to the login page
      navigate("/home");
    }
  }, []);

  return (
    <div className="homepage">
      <main>
        <section className="w-[100%] bg-[#F4F4F4]">
          <div className="main-box-container py-10">
            <div className="box-container-one">
              <div className="flex-col  flex w-[100%] md:px-4 px-2 m-auto max-w-[600px] h-[100%] ">
                <h1 className=" tracking-[2px] md:text-[44px] sm:text-[38px] text-[25px] text-alignment text-[#454444] font-[400]">
                  Reimagine home
                  <br />
                  management—easy,
                  <br />
                  efficient, and{" "}
                  <span className="text-[#DB6312]">built for you.</span>
                </h1>

                {isLaunched ? (
                  <div className="lg:mt-[130px] mt-[60px] flex flex-col">
                    <h2 className="md:text-[24px] text-[#294759] text-[20px]  font-normal text-center text-alignment">
                      Get the Home360 App
                    </h2>
                    <p className="md:text-[20px] text-[#3E3D3D] text-[18px] leading-tight text-center text-alignment">
                      Scan the QR code on your phone to download the app
                    </p>
                    <img
                      src={Qr}
                      alt="qr"
                      className="w-[180px] h-[180px] mt-6 mx-auto lg:ms-0"
                    />
                  </div>
                ) : (
                  <div className="lg:mt-[130px] mt-[60px] flex flex-col gap-6">
                    <div className="flex flex-col gap-2 w-full text-[#454444] ">
                      <h2 className="md:text-[24px] text-[20px]  font-normal text-center text-alignment">
                        GET NOTIFIED WHEN WE LAUNCH
                      </h2>
                      <p className="md:text-[21px] text-[18px] leading-tight text-center text-alignment">
                        Join the waitlist for exclusive
                        <br />
                        first access to this one-of-a-kind app!
                      </p>
                    </div>
                    <form
                      onSubmit={handleSubmit}
                      className="w-[100%] gap-4 flex form-css lg:flex-row flex-col"
                    >
                      <input
                        className="inputbutton"
                        type="text"
                        placeholder="Name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <input
                        className="inputbutton"
                        required
                        type="tel"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength="10"
                        placeholder="Phone number"
                        value={phoneNumber}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/\D/, ""); // Remove non-numeric characters
                        }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <button
                        className="bg-[#e65c1a] w-[100px]  text-white text-[14px] lg:py-2.5 py-2 rounded-sm"
                        type="submit"
                      >
                        {loading ? "Submitting..." : "Notify me"}
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center items-center box-container-two md:p-0 px-2">
              <img
                src={image1}
                alt="Dish washing"
                className="img-container-homepage"
              />
            </div>
          </div>
        </section>

        <section className="py-[80px] text-center">
          <h2 className="text-[#454444] text-center mb-16 font-[400] md:text-4xl sm:text-3xl text-2xl px-5">
            Your <span>all-in-one solution</span> for managing tasks, meals, and
            provisions with ease
          </h2>

          <div className="flex justify-center items-center flex-wrap md:gap-10 gap-6">
            <div className="w-fit">
              <div className=" w-[164px] h-[164px] bg-gradient-to-t from-[#294859] to-[#DEDADA] flex items-center justify-center rounded-lg">
                <img
                  src={Decorator}
                  alt="Staff Management"
                  className="w-[60%] text-[white]"
                />
              </div>
              <p className="text-[#454444] text-[14px] mt-3">
                Staff Management
              </p>
            </div>
            <div className="w-fit">
              <div className=" w-[164px] h-[164px] bg-gradient-to-t from-[#294859] to-[#DEDADA] flex items-center justify-center rounded-lg">
                <img
                  src={mealPlanning}
                  alt="Meal Planning"
                  className="w-[60%]"
                />
              </div>
              <p className="text-[#454444] text-[14px] mt-3">Meal Planning</p>
            </div>
            <div className="w-fit">
              <div className="w-[164px] h-[164px] bg-gradient-to-t from-[#294859] to-[#DEDADA] flex items-center justify-center rounded-lg">
                <img
                  src={provisions}
                  alt="Centralized Provisions List"
                  className="w-[60%]"
                />
              </div>
              <p className="text-[#454444] text-[14px] mt-3">
                Centralized Provisions List
              </p>
            </div>
          </div>
          <div className=" flex flex-wrap justify-center mt-12 gap-3 md:gap-4">
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px] xl:max-w-[160px]">
              <div className="w-[50px] h-[50px]">
                <img
                  src={staffManagement}
                  alt="staffManagement"
                  className="w-[100%] h-[100%]"
                />
              </div>
              <p>Staff Management</p>
            </div>
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px]">
              <div className="w-[50px] h-[50px]">
                <img
                  src={translate}
                  alt="translate"
                  className="w-[100%] h-[100%]"
                />
              </div>
              <p>Multi-language support</p>
            </div>
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px] xl:max-w-[140px]">
              <div className="w-[50px] h-[50px]">
                <img
                  src={playBack}
                  alt="playback"
                  className="w-[100%] h-[100%]"
                />
              </div>
              <p>Audio Playback</p>
            </div>
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px] ">
              <div className="w-[50px] h-[50px]">
                <img
                  src={calendar}
                  alt="calendar"
                  className="w-[100%] h-[100%]"
                />
              </div>
              <p>Attendance and Leave management</p>
            </div>
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px] xl:max-w-[140px]">
              <div className="w-[50px] h-[50px]">
                <img src={recipe} alt="recipe" className="w-[100%] h-[100%]" />
              </div>
              <p>Recipe Database</p>
            </div>
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px] xl:max-w-[140px]">
              <div className="w-[50px] h-[50px]">
                <img
                  src={mealSuggestion}
                  alt="mealSuggestion"
                  className="w-[100%] h-[100%]"
                />
              </div>
              <p>Meal Suggestions</p>
            </div>
            <div className="flex justify-center items-center flex-col w-[100%] max-w-[200px] xl:max-w-[100px]">
              <div className="w-[50px] h-[50px] flex items-center justify-center">
                <img
                  src={chatIcon}
                  alt="chatIcon"
                  className="w-[34px] h-[34px]"
                />
              </div>
              <p>Chat</p>
            </div>
          </div>
        </section>

        <section className=" relative">
          {contextHolder}
          <img
            src={PosterImage}
            alt="PosterImage"
            className="w-[100%] h-[100%] relative"
          />
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white">
            <h2 className="md:text-[24px] sm:text-[16px] text-[14px] font-[400]">
              New here?
            </h2>
            <h2 className="md:text-[24px] sm:text-[16px] text-[14px] font-[400]">
              See the exciting features
            </h2>
            <button
              onClick={() => openNotification("top", message, 0, "300px")}
              className=" md:w-[60px] md:h-[60px] sm:w-[50px] sm:h-[50px] w-[40px] h-[40px]  bg-[#e65c1a] text-white rounded-full border-none cursor-pointer md:text-[24px] text-[16px] md:mt-[20px] mt-[15px]"
            >
              ▶
            </button>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;
