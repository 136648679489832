import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { HiOutlinePlus } from "react-icons/hi2";
import { FaChevronRight } from "react-icons/fa";
import { GoTasklist } from "react-icons/go";


const Sidebar = ({ navigate, location, setIsSidebarOpen, isSidebarOpen }) => {
  const storedHome = localStorage.getItem("selectedHome");
  let userRole = null;
  let HouseManager = null;
  let userId = null;
  console.log(userId);

  if (storedHome) {
    const homeData = JSON.parse(storedHome);
    if (homeData.members && homeData.members.length > 0) {
      userRole = homeData.members[0].role; // Extract the role
      userId = homeData.members[0]?.userId; // Extract the role
      HouseManager = homeData?.members[0]?.staffDetail[0]?.category; // Extract the role
    }
  }

  const navigationTabs = [
    {
      name: "Add Task",
      icon: <HiOutlinePlus size={14} />,
      pageLink: "/add-tasks",
      rolesAllowed: ["Admin", "Family"],
    },
    {
      name: "My Task",
      icon: <IoEyeOutline size={14} />,
      pageLink: `${
        userId && userRole === "Staff" ? `/my-tasks/${userId}` : "/staff-tasks"
      }`,
      rolesAllowed: ["Staff"],
    },
    {
      name: "Staff Task",
      icon: <GoTasklist size={14} />,
      pageLink: "/staff-tasks",
      rolesAllowed: ["House Manager","Admin", "Family"],
    },
    {
      name: "Procedure",
      icon: <GoTasklist size={14} />,
      pageLink: "/procedures",
      rolesAllowed: ["Admin", "Family"],
    }
  ];
  // const filteredTabs = navigationTabs.filter((tab) =>
  //   tab.rolesAllowed.includes(userRole)
  // );
  const filteredTabs = navigationTabs?.filter(
    (tab) =>
      tab.rolesAllowed?.includes(userRole) ||
      tab.rolesAllowed?.includes(HouseManager)
  );

  return (
    <div className="bg-gray-100 border-r h-full border-gray-200">
      <div
        className={`p-4 flex flex-row items-center ${
          isSidebarOpen ? "justify-center" : "justify-end"
        }`}
      >
        {isSidebarOpen && (
          <h1
            className={`font-[600] text-[14px] whitespace-nowrap transition-opacity duration-500 ${
              isSidebarOpen ? "opacity-100" : "opacity-0"
            }`}
          >
            Task Manager
          </h1>
        )}
        {/* <button className="cursor-pointer transition-transform duration-300">
          <FaChevronRight
            className={`${
              isSidebarOpen ? "rotate-180" : "rotate-0"
            } transition-transform duration-300`}
          />
        </button> */}
      </div>

      <div className="flex flex-col gap-2 p-2">
        {filteredTabs.map((tab) => (
          <button
            onClick={() => {
              navigate(`${tab.pageLink}`);
            }}
            key={tab.pageLink}
            className={`flex text-[14px] ${
              !isSidebarOpen && "justify-center"
            } whitespace-nowrap items-center gap-2 py-1.5 px-2 rounded-md transition-all duration-300 ${
              location.pathname.startsWith(tab.pageLink)
                ? "bg-blue-100 text-blue-700"
                : "bg-gray-100 text-gray-700"
            } hover:bg-blue-100`}
          >
            {console.log(
              location.pathname.startsWith(tab.pageLink),
              location.pathname
            )}
            {tab.icon}
            {isSidebarOpen && tab.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
